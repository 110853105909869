import React, { useEffect, useState } from "react";
import classes from "./AgencyData.module.scss";
import constantsArr from "../../../constants/global-constants";
import { Button, Card, Drawer, Form, Input, notification, Pagination, Popconfirm, Select, Spin } from "antd";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import { DeleteOutlined, EditOutlined, EyeOutlined, FilterOutlined, QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import AppHelper from "../../../helpers/AppHelper";
import loadImg from "../../../assets/images/MenuIcon/loading-stroke.svg";
import unloadImg from "../../../assets/images/MenuIcon/unload.svg";
import transportImg from "../../../assets/images/MenuIcon/Transporting.svg";
import totalCountImg from "../../../assets/images/HeaderIcons/totalcount.svg"
import transportHeaderImg from "../../../assets/images/HeaderIcons/Transporting.svg";
import unloadHeaderImg from "../../../assets/images/HeaderIcons/unloadImg.svg";
import loadHeaderImg from "../../../assets/images/HeaderIcons/load-white-stroke.svg";

export const AgencyData = () => {
    const navigate = useNavigate();
    const params = new URLSearchParams(document.location.search);
    const [agencyData, setAgencyData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showPagination, setShowPagination] = useState(true);
    const [currentPagePagination, setCurrentPagePagination] = useState(params.get('currentPage') ?? 1);
    const [currentPageSizePagination, setCurrentPageSizePagination] = useState(constantsArr.DEFAULT_PAGINATION_SIZE);
    const [open, setOpen] = useState(false);
    const [agencyFilterForm] = Form.useForm()
    const [currentSetFilterValues, setCurrentSetFilterValues] = useState({})
    const [currentPage, pageSize] = [params.get('currentPage') ?? constantsArr.PAGINATION_CURRENT_PAGE, params.get('pageSize') ?? constantsArr.DEFAULT_PAGINATION_SIZE];
    const [sortValue, setSortValue] = useState('updatedAt:desc'); // Default sorting
    const [sortSpinning, setSortSpinning] = useState(false);
    const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
    const sessionGlobalValues = AppHelper.decryptText(sessionStorage.getItem("globalValues"));
    const [agencyCompanyNameDropdownValues, setAgencyCompanyNameDropdownValues] = useState([]);
    const [loadingAgencyLists, setloadingAgencyLists] = useState([]);

    const navigateToCreateForm = (e) => {
        e.preventDefault();
        navigate("/agencies/create");
    };

    const loadAllAgencies = (currentPage, pageSize, filterArgs = []) => {
        setCurrentPagePagination(currentPage);
        AppHelper.getAllAgencies(sortValue, currentPage, pageSize, true, filterArgs, globalTpProjectValues?.projectGlobal).then((response) => {
            setSortSpinning(false);
            setLoading(false);
            if (response?.error) {
                console.log(response?.error?.data);
            } else {
                setAgencyData(response);
                setShowPagination(true);
            }
        });
    };

    const deleteAgency = (agencyId) => {
      setLoading(true)
      AppHelper.deleteAgency(agencyId).then(response => {
        setLoading(false)
        if (response?.error) {
            notification.error({
                message: 'Unable to delete agency record',
                description: response?.error?.response.data || 'Unknown error',
                duration: 3,
            });
        } else {
            notification.success({
                message: "Success",
                description: "Agency record has been deleted successfully!",
                duration: 1.5,
            });
          loadAllAgencies(currentPagePagination, currentPageSizePagination);
        }
      })
    }

    const changePageNumber = (currentPage, pageSize) => {
        setCurrentPageSizePagination(pageSize);
        if (currentPage === currentPagePagination && currentPageSizePagination === pageSize) {
            // page number is being changed then go to next page
            currentPage++;
        }
        setCurrentPagePagination(currentPage)
        loadAllAgencies(currentPage, pageSize, currentSetFilterValues);
    };

    const showDrawer = () => setOpen(true)

    const onClose = () => setOpen(false);

    const filterAgencies = async (formValues) => {
        const agency = loadingAgencyLists.find((item) => item?.attributes?.Company_name === formValues?.agency_id && item?.attributes?.Project?.data?.id === sessionGlobalValues?.projectGlobal)
        formValues.agency_id = agency?.id ?? ""
        loadAllAgencies(currentPage, pageSize, formValues);
        setOpen(false)
        setCurrentSetFilterValues(formValues)
        setSortSpinning(true);
    }

    const resetFilterForm = (e) => {
        e.preventDefault()
        agencyFilterForm.resetFields()
    }

    const getAgencyTypeImage = (type) => {
        switch (type) {
            case 'Loading':
                return <img src={loadImg} alt={type} title="Loading" />;
            case 'Unloading':
                return <img src={unloadImg} alt={type} title="Unloading" />;
            case 'Transportation':
                return <img src={transportImg} alt={type} title="Transporation" />;
            // Add cases for other agency types if needed
            default:
                return "default"; // Provide a default image or handle accordingly
        }
    };

    const handleSortChange = (value) => {
        setSortSpinning(true);
        // Update the sorting state
        setSortValue(value);
        setCurrentPagePagination(currentPage);
    };

    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const handleTypeOfAgencyChange = (selectedTypes) => {
        agencyFilterForm.setFieldsValue({
            agency_id: null
        })
        setAgencyCompanyNameDropdownValues([]);
        // Convert array of selected types into a single string
        const selectedTypesString = selectedTypes.join(',');

        const filteredValues = AppHelper.getAgencyDropDown(loadingAgencyLists, selectedTypesString, sessionGlobalValues);
        setAgencyCompanyNameDropdownValues(filteredValues);
    };


    useEffect(() => {
        document.title = "Agencies List";
        AppHelper.getAgenciesByProject(sessionGlobalValues?.projectGlobal).then((response) => {
            setloadingAgencyLists(response)
        });
        loadAllAgencies(currentPage, pageSize, currentSetFilterValues);
    }, [currentPage, pageSize, currentSetFilterValues, sortValue]);

    return (
        <>
            <>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    className={classes['header-fixed-agency']}
                >
                    <Grid item lg={9} md={8} xs={12}>
                        <Typography
                            variant="h1"
                            fontSize="1.5rem"
                            fontWeight="bold"
                            className="title-un-loading-text"
                        >
                            Agencies
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        lg={3}
                        md={4}
                        xs={12}
                        container
                        spacing={2}
                        className={classes["action-button"]}
                    >
                        <Grid item lg={4} md={4} xs={12} className={classes["form-select-div-select"]}>
                            <Form>
                                <Form.Item label="Sort By">
                                    <Select className={classes["select-new-entry"]} defaultValue="updatedAt:desc" onChange={handleSortChange}>
                                        <Select.Option value="updatedAt:desc">Updated Date</Select.Option>
                                        <Select.Option value="Company_name:asc">Company Name Ascending</Select.Option>
                                        <Select.Option value="Company_name:desc">Company Name Descending</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Form>
                        </Grid>
                        <Grid item lg={4} md={4} xs={4}>
                            <UIButton
                                onClick={showDrawer}
                                variant="outlined"
                                startIcon={<FilterOutlined />}
                                className={classes["filter-new-entry"]}
                            >
                                Filters
                            </UIButton>
                            <Drawer title="Filters" onClose={onClose} open={open}>
                                <Form layout="vertical" form={agencyFilterForm} onFinish={filterAgencies}>
                                    <Form.Item label="Type of Agency" name="Type_of_agency">
                                        <Select id='Type_of_agency' mode='multiple' allowClear placeholder="Please select" options={constantsArr.AGENCY_TYPES} onChange={(e) => handleTypeOfAgencyChange(e)} />
                                    </Form.Item>
                                    <Form.Item label="Company Name" name="Company_name">
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={filterOption}
                                            style={{ width: "100%" }}
                                            options={agencyCompanyNameDropdownValues}
                                            placeholder="Select"
                                        />
                                    </Form.Item>
                                    <Form.Item label="Contact Person" name="Contact_person">
                                        <Input
                                            placeholder="Enter Contact Person"
                                            addonBefore={<SearchOutlined />}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Mobile Number" name="Mobile_Number">
                                        <Input
                                            placeholder="Enter Mobile Number"
                                            addonBefore={<SearchOutlined />}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Location" name="Location">
                                        <Input
                                            placeholder="Enter Location"
                                            addonBefore={<SearchOutlined />}
                                        />
                                    </Form.Item>
                                    <div className={classes["search-button-area"]}>
                                        <UIButton size="medium" variant="outlined" color="error" onClick={resetFilterForm}>
                                            Reset
                                        </UIButton>
                                        <UIButton
                                            size="medium"
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            className={classes["new-filter-apply"]}
                                        >
                                            Apply
                                        </UIButton>
                                    </div>
                                </Form>
                            </Drawer>
                        </Grid>
                        <Grid item lg={4} md={4} xs={4}>
                            <UIButton
                                size="small"
                                variant="contained"
                                onClick={navigateToCreateForm}
                                className={classes["add-new-entry"]}
                            >
                                + Add new
                            </UIButton>
                        </Grid>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12} container spacing={1} className={classes["headerMarginTop"]}>
                        <Grid item lg={3} md={2} xs={1}>
                            <Typography variant="body1" className={`${classes["total-items"]} ${classes["total-item-first"]}`} fontSize={14}>
                                <div className={classes["header-grid-flex"]}>
                                    <div><img src={totalCountImg} alt="Total count" className={classes["header-grid-image"]} /></div>
                                    <div>
                                        <Typography variant="body" color="white" className={classes["header-text-fontSize"]} title={agencyData?.meta?.pagination.total ?? 0}>
                                            {AppHelper.formatNumber(agencyData?.meta?.pagination.total ?? 0)}
                                        </Typography><br></br>
                                        <Typography variant="body" color="white">
                                            Total Count
                                        </Typography>
                                    </div>
                                </div>
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={2} xs={1}>
                            <Typography variant="body1" className={`${classes["total-items"]} ${classes["total-item-second"]}`} fontSize={14}>
                                <div className={classes["header-grid-flex"]}>
                                    <div><img src={transportHeaderImg} alt="Total count" className={classes["header-grid-image"]} /></div>
                                    <div>
                                        <Typography variant="body" color="white" className={classes["header-text-fontSize"]} title={agencyData?.meta?.totalTransportingAgency ?? 0}>
                                            {AppHelper.formatNumber(agencyData?.meta?.totalTransportingAgency ?? 0)}
                                        </Typography><br></br>
                                        <Typography variant="body" color="white">
                                            Transporting Agency
                                        </Typography>
                                    </div>
                                </div>
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={2} xs={1}>
                            <Typography variant="body1" className={`${classes["total-items"]} ${classes["total-item-third"]}`} fontSize={14}>
                                <div className={classes["header-grid-flex"]}>
                                    <div><img src={loadHeaderImg} alt="Total count" className={classes["header-grid-image"]} /></div>
                                    <div>
                                        <Typography variant="body" color="white" className={classes["header-text-fontSize"]} title={agencyData?.meta?.totalLoadingAgency ?? 0}>
                                            {AppHelper.formatNumber(agencyData?.meta?.totalLoadingAgency ?? 0)}
                                        </Typography><br></br>
                                        <Typography variant="body" color="white">
                                            Loading Agency
                                        </Typography>
                                    </div>
                                </div>
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={2} xs={1}>
                            <Typography variant="body1" className={`${classes["total-items"]} ${classes["total-item-fourth"]}`} fontSize={14}>
                                <div className={classes["header-grid-flex"]}>
                                    <div><img src={unloadHeaderImg} alt="Total count" className={classes["header-grid-image"]} /></div>
                                    <div>
                                        <Typography variant="body" color="white" className={classes["header-text-fontSize"]} title={agencyData?.meta?.totalUnloadingAgency ?? 0}>
                                            {AppHelper.formatNumber(agencyData?.meta?.totalUnloadingAgency ?? 0)}
                                        </Typography><br></br>
                                        <Typography variant="body" color="white">
                                            Unloading Agency
                                        </Typography>
                                    </div>
                                </div>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </>
            <Grid container spacing={0}>
                <Grid container spacing={0} className={classes["marginTop20"]}>
                    {!agencyData?.data || sortSpinning ? (
                        <Spin size="large" tip="Please Wait ...">
                            <div className={classes["content"]} />
                        </Spin>
                    ) : agencyData?.data?.length === 0 ? (
                        // Show message when data is not available
                        <Typography variant="body1">
                            No agency record is available
                        </Typography>
                    ) : (agencyData?.data?.map((item) => (
                        <Grid key={item.id} item lg={4} md={6} xs={12}>
                            <Card bordered={true} className={classes["card-box"]}>
                                <div className={classes["AgencyDetails"]}>
                                    <Typography
                                        component="div"
                                        gutterBottom
                                        fontWeight="bold"
                                    >
                                        {item?.attributes?.Company_name?.length > 20 ? <span title={item?.attributes?.Company_name}>{item?.attributes?.Company_name.substring(0, 20)}....</span> : item?.attributes?.Company_name ?? "-"}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Location :
                                        <span className={classes["AgencyDetailsValue"]}>
                                            {item?.attributes?.Location}
                                        </span>
                                    </Typography>
                                    <Typography gutterBottom variant="body2">
                                        Contact Person :
                                        <span className={classes["AgencyDetailsValue"]}>
                                            {item?.attributes?.Contact_person}
                                        </span>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Mobile Number :
                                        <span className={classes["AgencyDetailsValue"]}>
                                            {item?.attributes?.Mobile_Number}
                                        </span>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Type of Agency:
                                        <span className={classes["AgencyDetailsValue"]}>
                                            {item?.attributes?.Type_of_agency && item?.attributes?.Type_of_agency.map((type, index) => (
                                                <span key={index} className={classes["agency-type-image"]}>
                                                    {getAgencyTypeImage(type)}
                                                </span>
                                            ))}
                                        </span>
                                    </Typography>
                                </div>

                                <div className={classes["card-button"]}>
                                    <ul className={classes["action-items"]}>
                                        <li>
                                            <Link
                                                to={`/agencies/${item?.id}?currentPage=${currentPagePagination}&pageSize=${pageSize}`}
                                                title="View Agency Details"
                                            >
                                                <EyeOutlined className={classes["card-icons"]} />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={`/agencies/edit/${item?.id}?currentPage=${currentPagePagination}&pageSize=${pageSize}`}
                                                title="Edit Agency Details"
                                            >
                                                <EditOutlined className={classes["card-icons"]} />
                                            </Link>
                                        </li>
                                        <li>
                                            <Popconfirm
                                                okText="Yes"
                                                cancelText="No"
                                                title="Delete the agency"
                                                description="Are you sure, you want to delete this agency record?"
                                                  onConfirm={e => deleteAgency(item?.id)}
                                                icon={<QuestionCircleOutlined style={{ color: '#ff0000' }} />}
                                                >
                                                    <DeleteOutlined className={`${classes["card-icons"]} ${classes["card-icons-delete"]}`} style={{ color: "#ff0000" }} />
                                            </Popconfirm>
                                        </li>
                                    </ul>
                                </div>
                            </Card>
                        </Grid>
                    ))
                    )}

                </Grid>
                {showPagination === true && agencyData?.data?.length > 0 ? (
                    <div className={classes["pagination-box"]}>
                        <Pagination
                            current={currentPagePagination}
                            defaultCurrent={currentPage}
                            defaultPageSize={currentPageSizePagination}
                            showTotal={(total) => `Total ${total} items`}
                            responsive
                            pageSize={currentPageSizePagination}
                            onChange={changePageNumber}
                            showSizeChanger={false}
                            total={agencyData?.meta?.pagination?.total}
                        />
                    </div>
                ) : (
                    ""
                )}
            </Grid>
        </>
    );
};
