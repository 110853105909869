import React, { useEffect, useState } from "react";
import { Card, Checkbox, Col, Drawer, Form, Modal, Pagination, Popconfirm, Radio, Row, Select, Spin, Upload, notification } from "antd";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import { EditOutlined, EyeOutlined, FilterOutlined, ImportOutlined, UploadOutlined, LoadingOutlined, QuestionCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import classes from "./VehicleData.module.scss";
import { Link, useNavigate } from "react-router-dom";
import "../../../styles/index.scss";
import constantsArr from "../../../constants/global-constants";
import AppHelper from "../../../helpers/AppHelper";
import importVehicleSampleFile from '../../../assets/import-samples/vehicle_import_sample_file.xlsx'
import totalCountImg from "../../../assets/images/HeaderIcons/totalcount.svg"
import checkBoxIcon from "../../../assets/images/MenuIcon/check.png";
import exclamationMarkIcon from "../../../assets/images/MenuIcon/warning-sign.png";

export const VehicleData = () => {
    const navigate = useNavigate();
    const params = new URLSearchParams(document.location.search);
    const [vehicleData, setVehicleData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showPagination, setShowPagination] = useState(true);
    const [currentPagePagination, setCurrentPagePagination] = useState(params.get('currentPage') ?? 1);
    const [currentPageSizePagination, setCurrentPageSizePagination] = useState(constantsArr.DEFAULT_PAGINATION_SIZE);
    const [open, setOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [vehicleFilterForm] = Form.useForm()
    const [currentPage, pageSize] = [params.get('currentPage') ?? constantsArr.PAGINATION_CURRENT_PAGE, params.get('pageSize') ?? constantsArr.DEFAULT_PAGINATION_SIZE];
    const [currentSetFilterValues, setCurrentSetFilterValues] = useState({})
    const [uploadedFile, setUploadedFile] = useState({});
    const allowedImportFileTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    const maxUploadedImportFileSize = constantsArr.MAX_IMPORT_EXCEL_FILE_SIZE_MB * 1024 * 1024
    const [sortValue, setSortValue] = useState('updatedAt:desc'); // Default sorting
    const [sortSpinning, setSortSpinning] = useState(false);
    const [hideDeleteIcon, setHideDeleteIcon] = useState(false); // State to manage delete icon visibility
    const [vehicleDropDown, setVehicleDropDown] = useState([]);
    const [ownerDropDown, setOwnerDropDown] = useState([]);
    const [noOfTyresDropDown, setNoOfTyresDropDown] = useState([]);
    const [vehicleCategoryDropDown, setVehicleCategoryDropDown] = useState([]);
    const [cancelledCheckBoxFilter, setCancelledCheckBoxFilter] = useState(false)
    const [projectGlobal, setProjectGlobal] = useState(null); // State to hold projectGlobal value
    const [isCurrentProject, setIsCurrentProject] = useState(false);
    const sessionGlobalValues = AppHelper.decryptText(sessionStorage.getItem("globalValues"));
    const sessionVehicleFilterValues = AppHelper.decryptText(sessionStorage.getItem('vehicles_schema_filter_form_values'))
    const [isProjectVehicleChecked, setIsProjectVehicleChecked] = useState(false);

    const props = {
        name: 'file',
        action: false,
        beforeUpload: file => checkBeforeUpload(file),
        maxCount: 1,
        onRemove: () => { setUploadedFile({}) },
        uploadedFile
    };

    const [excelImportErrors, setExcelImportErrors] = useState({});
    const [isModalOpenExcelErrors, setIsModalOpenExcelErrors] = useState(false);

    const redirectToVehicleCreate = () => navigate("/vehicles/create")

    const changePageNumber = (currentPage, pageSize) => {
        setCurrentPageSizePagination(pageSize);
        if (currentPage === currentPagePagination && currentPageSizePagination === pageSize) {
            // page number is being changed then go to next page
            currentPage++;
        }
        loadAllVehicles(sortValue, currentPage, pageSize, sessionVehicleFilterValues);
    };

    const loadAllVehicles = (sortOrder, currentPage, pageSize, filterArgs = []) => {
        setCurrentPagePagination(currentPage);
        setSortSpinning(true);
        AppHelper.getAllVehicles(sortOrder, currentPage, pageSize, true, filterArgs).then((response) => {
            setSortSpinning(false);
            setLoading(false);
            if (response?.error) {
                console.log(response?.error?.data);
            } else {
                setVehicleData(response);
                setShowPagination(true);
            }
        });
    };

    const checkBeforeUpload = (file) => {
        const fileType = file?.type ?? ""
        const fileSize = file?.size ?? 0
        setUploadedFile({ ...uploadedFile, file })
        if (!fileType) {
            notification.error({ message: "Error", description: "Invalid file detected" })
            return false
        }

        if (allowedImportFileTypes.includes(fileType)) {
            // check size of uploaded file should not be too big
            if (fileSize > maxUploadedImportFileSize) {
                notification.error({ message: "Error", description: "The uploaded file exceeds the maximum allowable size of 8 MB." })
                return false
            }
        } else {
            // wrong file type detected throw error
            notification.error({ message: "Error", description: "The uploaded file must be in either the xls or xlsx format." })
            return false
        }
        return false
    }

    const showDrawer = () => setOpen(true);

    const onClose = () => setOpen(false);

    const showModalPopUp = () => {
        setIsModalOpen(true)
        setHideDeleteIcon(false);
    }

    const handleModelCancel = () => setIsModalOpen(false);

    const handleModelExcelErrorsCancel = () => setIsModalOpenExcelErrors(false)

    const handleUploadFiles = async () => {
        if (Object.keys(uploadedFile).length === 0) {
            notification.error({ message: "Error", description: "Please upload file to import" })
            return false
        }
        // Set loading to true when upload starts
        setLoading(true);
        setHideDeleteIcon(true); // Hide delete icon when upload button is clicked
        const fileType = uploadedFile?.file?.type ?? ""
        const fileSize = uploadedFile?.file?.size ?? 0
        if (!fileType) {
            notification.error({ message: "Error", description: "Invalid file detected" });
            setLoading(false); // Set loading to false when upload finishes
            return false;
        }

        if (allowedImportFileTypes.includes(fileType)) {
            // check size of uploaded file should not be too big
            if (fileSize > maxUploadedImportFileSize) {
                notification.error({ message: "Error", description: "The uploaded file exceeds the maximum allowable size of 8 MB." })
                setLoading(false);
                return false
            }
        } else {
            // wrong file type detected throw error
            notification.error({ message: "Error", description: "The uploaded file must be in either the xls or xlsx format." })
            setLoading(false);
            return false
        }
        // call to an api to import
        const fileImportResponse = await AppHelper.importVehicleExcel(uploadedFile)
        if (fileImportResponse && Object.keys(fileImportResponse).length > 0) {
            setExcelImportErrors(fileImportResponse);
            setIsModalOpenExcelErrors(true);
            setIsModalOpen(false);
            setLoading(false);
        } else {
            // Check if fileImportResponse is undefined or null
            if (fileImportResponse === undefined || fileImportResponse === null || fileImportResponse === "ERR_NETWORK") {
                // Show error message if fileImportResponse is undefined or null
                notification.error({
                    message: "Error",
                    description: "Failed to import data. Please try again later.",
                });
                setLoading(false);
            } else {
                setIsModalOpenExcelErrors(false)
                setIsModalOpen(false)
                loadAllVehicles(sortValue, currentPage, pageSize, sessionVehicleFilterValues);
                setLoading(false);
                notification.success({
                    message: "Success",
                    description: "Vehicle data imported successfully!",
                    duration: 1.5,
                });
            }
        }
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const filterVehicles = async (formValues) => {
        // Include current project filter if checkbox is checked
        if (isCurrentProject) {
            // Add project filtering logic based on projectGlobal
            formValues.project = projectGlobal; // Use projectGlobal to filter
        } else {
            delete formValues.project; // Remove project filter if checkbox is not checked
        }
        formValues.Status_Vehicle = cancelledCheckBoxFilter
        formValues.projectFilter = isProjectVehicleChecked
        loadAllVehicles(sortValue, currentPage, pageSize, formValues);
        setOpen(false)
        setCurrentSetFilterValues(formValues)
        sessionStorage.setItem('vehicles_schema_filter_form_values', AppHelper.encryptText(formValues))
    }

    const handleCheckboxChange = (e) => {
        currentSetFilterValues.projectFilter = e.target.checked
        setIsCurrentProject(e.target.checked); // Update state based on checkbox
        setIsProjectVehicleChecked(e.target.checked)
        loadAllVehicles(sortValue, currentPage, pageSize, currentSetFilterValues);
        sessionStorage.setItem('vehicles_schema_filter_form_values', AppHelper.encryptText(currentSetFilterValues))
    };

    const handleChange = (e) => {
        setCancelledCheckBoxFilter(e.target.value)
    }

    const handleDeleteVehicle = async (loadingId) => {
        const response = await AppHelper.deleteVehicle(loadingId);
        if (response?.error) {
            console.error('Error response:', response); // Log the response for debugging
            notification.error({
                message: 'Error: Failed to delete Vehicle',
                description: response?.error?.message || 'Unknown error',
                duration: 3,
            });
        } else {
            setTimeout(() => {
                notification.success({
                    message: 'Success',
                    description: 'Vehicle has been deleted successfully.',
                    duration: 3,
                });
            }, 100)
            loadAllVehicles(sortValue, currentPage, pageSize, currentSetFilterValues);
        }
    };

    const resetFilterForm = (e) => {
        e.preventDefault()
        setCancelledCheckBoxFilter(null)
        vehicleFilterForm.resetFields()
    }

    const handleSortChange = (value) => {
        setSortValue(value);
        setCurrentPagePagination(1);
        loadAllVehicles(value, 1, pageSize, sessionVehicleFilterValues);
    };

    // Function to show a notification when attempting to close modal during uploading
    const showCannotCloseNotification = () => {
        notification.warning({
            message: 'Uploading in Progress',
            description: 'Please wait for the file upload to complete before closing the modal.',
            duration: 3,
        });
    };


    useEffect(() => {
        document.title = "Vehicle List";
        setProjectGlobal(sessionGlobalValues?.projectGlobal); // Store projectGlobal value in state
        // Fetch vehicle and agency data
        AppHelper.getVehiclesWithoutPagination().then((response) => {
            setNoOfTyresDropDown(AppHelper.getNoOfTyresDropDownWithoutPagination(response?.data))
            setOwnerDropDown(AppHelper.getOwnerDropDownWithoutPagination(response?.data))
            setVehicleDropDown(AppHelper.getVehicleNumberDropDownWithoutPagination(response?.data));
            if (sessionVehicleFilterValues && Object.keys(sessionVehicleFilterValues).length > 0) {
                // if filter values available in session then display and keep selected the values in filter form
                let updatedSessionVehicleFilterFormValues = { ...sessionVehicleFilterValues }
                const vehicleDetailsByName = response?.data?.find((obj) => obj?.Vehicle_Number === sessionVehicleFilterValues?.Vehicle_Number);
                const ownerDetailsByName = response?.data?.find((obj) => obj?.Owner === sessionVehicleFilterValues?.Owner);
                const noOfTyresDetailsByName = response?.data?.find((obj) => obj?.No_of_Tyres === sessionVehicleFilterValues?.No_of_Tyres);
                const VehicleCategoryDetailsByName = response?.data?.find((obj) => obj?.Vehicle_Category === sessionVehicleFilterValues?.Vehicle_Category);

                updatedSessionVehicleFilterFormValues.Vehicle_Number = vehicleDetailsByName?.Vehicle_Number ?? null
                updatedSessionVehicleFilterFormValues.Owner = ownerDetailsByName?.Owner ?? null
                updatedSessionVehicleFilterFormValues.No_of_Tyres = noOfTyresDetailsByName?.No_of_Tyres ?? null
                updatedSessionVehicleFilterFormValues.Vehicle_Category = VehicleCategoryDetailsByName?.Vehicle_Category ?? null
                setCancelledCheckBoxFilter(sessionVehicleFilterValues.Status_Vehicle)
                setIsProjectVehicleChecked(sessionVehicleFilterValues.projectFilter)
                vehicleFilterForm.setFieldsValue(updatedSessionVehicleFilterFormValues)
            }

            setVehicleCategoryDropDown(AppHelper.getVehicleCategoryDropDownWithoutPagination(response?.data));
        });
        loadAllVehicles(sortValue, currentPage, pageSize, sessionVehicleFilterValues);
    }, []);

    return (
        <>
            <Grid
                container
                spacing={0}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes['header-fixed-vehicle']}
            >
                <Grid item lg={5} md={12} xs={12}>
                    <Typography
                        variant="h1"
                        fontSize="1.5rem"
                        fontWeight="bold"
                        className="title-un-loading-text"
                    >
                        Vehicles
                    </Typography>
                </Grid>

                <Grid
                    item
                    lg={7}
                    md={12}
                    xs={12}
                    container
                    className={classes["action-button"]}
                    spacing={2}
                >
                    <Grid item lg={3} md={3} xs={12} className={classes["form-select-div"]}>
                        <Form form={vehicleFilterForm}>
                            <Checkbox onChange={handleCheckboxChange} checked={isProjectVehicleChecked}>Project Vehicles</Checkbox>
                        </Form>
                    </Grid>
                    <Grid item lg={2} md={2} xs={12} className={classes["form-select-div"]}>
                        <Form>
                            <Form.Item label="Sort By">
                                <Select className={classes["select-new-entry"]} defaultValue="updatedAt:desc" onChange={handleSortChange}>
                                    <Select.Option value="updatedAt:desc">Updated Date</Select.Option>
                                    <Select.Option value="Vehicle_Number:asc">Vehicle No. Ascending</Select.Option>
                                    <Select.Option value="Vehicle_Number:desc">Vehicle No. Descending</Select.Option>
                                </Select>
                            </Form.Item>
                        </Form>
                    </Grid>
                    <Grid item lg={2} md={2} xs={4}>
                        <UIButton
                            type="primary"
                            onClick={showModalPopUp}
                            variant="outlined"
                            startIcon={<ImportOutlined />}
                            className={classes["import-new-entry"]}
                        >
                            Import
                        </UIButton>
                        <Modal
                            centered
                            open={isModalOpen}
                            onCancel={handleModelCancel}
                            footer={(_) => (
                                <>
                                    <UIButton
                                        onClick={handleModelCancel}
                                        variant="outlined"
                                        color="error"
                                        className={classes['model-buttons']}
                                    >
                                        Cancel
                                    </UIButton>
                                    <UIButton
                                        onClick={handleUploadFiles}
                                        variant="contained"
                                    >
                                        Upload
                                    </UIButton>
                                </>
                            )}
                        >
                            <Upload {...props}>
                                <UIButton
                                    startIcon={<UploadOutlined />}
                                    className={classes['upload-file-entry']}
                                    variant="outlined"
                                >
                                    Browse File
                                </UIButton>
                            </Upload>
                            <h4>Please choose file .xls and .xlsx only. <a href="..." download={true}>Click here</a> to download the sample file.</h4>
                        </Modal>
                    </Grid>
                    <Grid item lg={2} md={2} xs={4}>
                        <UIButton
                            onClick={showDrawer}
                            variant="outlined"
                            startIcon={<FilterOutlined />}
                            className={classes["filter-new-entry"]}
                        >
                            Filters
                        </UIButton>
                        <Drawer title="Filters" onClose={onClose} open={open}>
                            <Form layout="vertical" form={vehicleFilterForm} onFinish={filterVehicles}>
                                <Form.Item label="Vehicle Number" name="Vehicle_Number">
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                        style={{ width: "100%" }}
                                        options={vehicleDropDown}
                                        placeholder="Select"
                                    />
                                </Form.Item>
                                <Form.Item label="Owner" name="Owner">
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                        style={{ width: "100%" }}
                                        options={ownerDropDown}
                                        placeholder="Select"
                                    />
                                </Form.Item>
                                <Form.Item label="No of Tyres" name="No_of_Tyres">
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                        style={{ width: "100%" }}
                                        options={noOfTyresDropDown}
                                        placeholder="Select"
                                    />
                                </Form.Item>
                                <Form.Item label="Vehicle Category" name="Vehicle_Category">
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                        style={{ width: "100%" }}
                                        options={vehicleCategoryDropDown}
                                        placeholder="Select"
                                    />
                                </Form.Item>
                                <Radio.Group name="Status_Vehicle" onChange={handleChange} value={cancelledCheckBoxFilter} style={{ width: '100%' }}>
                                    <Row gutter={[24]} wrap={true}>
                                        <Col span={24}>
                                            <Form.Item
                                                messageVariables=""
                                                wrapperCol={{ span: 30 }}
                                                labelCol={{ span: 30 }}
                                                className={classes['ant-form-item-radio']}
                                            >
                                                <Row gutter={[24, 24]} wrap={true}>
                                                    <Col span={12}>
                                                        <Radio value={1} name="Status_Vehicle_Verified">Verified</Radio>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Radio value={2} name="Status_Vehicle_Pending">Pending</Radio>
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item
                                                messageVariables=""
                                                wrapperCol={{ span: 30 }}
                                                labelCol={{ span: 30 }}
                                                className={classes['ant-form-item-radio']}
                                            >
                                                <Row gutter={[24, 24]} wrap={true}>
                                                    <Col>
                                                        <Radio value={3} name="Status_Vehicle_forcefully_validated">Forcefully Validated</Radio>
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Radio.Group>
                                <div className={classes["search-button-area"]}>
                                    <UIButton size="medium" variant="outlined" color="error" onClick={resetFilterForm}>
                                        Reset
                                    </UIButton>
                                    <UIButton
                                        size="medium"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className={classes["upload-file-button"]}
                                    >
                                        Apply
                                    </UIButton>
                                </div>
                            </Form>
                        </Drawer>
                    </Grid>
                    <Grid item lg={2} md={2} xs={4}>
                        <UIButton
                            size="small"
                            variant="contained"
                            onClick={redirectToVehicleCreate}
                            className={classes["add-new-entry"]}
                        >
                            + Add new
                        </UIButton>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} xs={12} container spacing={1}>
                    <Grid item lg={12} md={12} xs={12}>
                        <Typography variant="body1" className={`${classes["total-items"]} ${classes["total-item-first"]}`} fontSize={14}>
                            <div className={classes["header-grid-flex"]}>
                                <div><img src={totalCountImg} alt="Total count" /></div>
                                <div>
                                    <Typography variant="body" color="white" className={classes["header-text-fontSize"]} title={vehicleData?.meta?.pagination?.total ?? 0}>
                                        {AppHelper.formatNumber(vehicleData?.data?.attributes?.pagination?.total ?? 0)}
                                    </Typography><br></br>
                                    <Typography variant="body" color="white">
                                        Total Count
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={0} className={classes["marginTop20"]}>
                {!vehicleData?.data?.attributes?.results || sortSpinning ? (
                    <Spin size="large" tip="Please Wait ...">
                        <div className={classes["content"]} />
                    </Spin>
                ) : vehicleData?.data?.attributes?.results?.length === 0 ? (
                    // Show message when data is not available
                    <Typography variant="body1">
                        No vehicle record is available
                    </Typography>
                ) : (vehicleData?.data?.attributes?.results?.map((item) => (

                    <Grid key={item.id} item lg={4} md={6} xs={12}>
                        <Card bordered={true} className={classes["card-box"]}>
                            <div className={classes["AgencyDetails"]}>
                                <Typography
                                    component="div"
                                    gutterBottom
                                    fontWeight="bold"
                                >
                                    {item?.Vehicle_Number ?? "-"}
                                </Typography>
                                <Typography gutterBottom variant="body2">
                                    Owner :
                                    <span className={classes["AgencyDetailsValue"]}>
                                        {item?.Owner ?? "-"}
                                    </span>
                                </Typography>
                                <Typography gutterBottom variant="body2">
                                    No of Tyres :
                                    <span className={classes["AgencyDetailsValue"]}>
                                        {item?.No_of_Tyres ?? 0}
                                    </span>
                                </Typography>
                                <Typography gutterBottom variant="body2">
                                    Vehicle Category :
                                    <span className={classes["AgencyDetailsValue"]}>
                                        {item?.Vehicle_Category ?? '-'}
                                    </span>
                                </Typography>
                            </div>
                            <div className={classes["card-button"]}>
                                <ul className={classes["action-items"]}>
                                    <li>
                                        <Link
                                            to={`/vehicles/view/${item?.id}?currentPage=${currentPagePagination}&pageSize=${pageSize}`}
                                            title="View Vehicle Details"
                                        >
                                            <EyeOutlined
                                                className={classes["card-icons"]}
                                                style={{ color: "#000", float: "right" }}
                                            />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={`/vehicles/edit/${item?.id}?currentPage=${currentPagePagination}&pageSize=${pageSize}`}
                                            title="Edit Vehicle Details"
                                        >
                                            <EditOutlined
                                                className={classes["card-icons"]}
                                                style={{ color: "#000", float: "right" }}
                                            />
                                        </Link>
                                    </li>
                                    <li>
                                        <Popconfirm
                                            title="Delete the Entry"
                                            description="Are you sure, you want to delete this vehicle record?"
                                            icon={<QuestionCircleOutlined style={{ color: '#ff0000' }} />}
                                            onConfirm={() => handleDeleteVehicle(item?.id)}
                                        >
                                            <DeleteOutlined
                                                title="Delete loading"
                                                className={classes['card-icons']}
                                                style={{ color: '#ff0000', float: 'right' }}
                                            />
                                        </Popconfirm>
                                    </li>
                                </ul>
                                {
                                    item?.Validated_Through_API === true
                                        ?
                                        <div className={classes["verified-vehicle-ribbon-green"]}>
                                            <img src={checkBoxIcon} alt="checkboximg" className={classes["verify-icon"]} />
                                            <Typography variant="body2" color="initial">Verified</Typography>
                                        </div>
                                        :
                                        item?.Forcefully_Validated === true
                                            ?
                                            <div className={classes["verified-vehicle-ribbon-light-green"]}>
                                                <img src={checkBoxIcon} alt="checkboximg" className={classes["verify-icon"]} />
                                                <Typography variant="body2" color="initial">Forced</Typography>
                                            </div>
                                            :
                                            <div className={classes["verified-vehicle-ribbon-yellow"]}>
                                                <img src={exclamationMarkIcon} alt="exclamation" className={classes["verify-icon"]} />
                                                <Typography variant="body2" color="initial">Pending</Typography>
                                            </div>
                                }
                            </div>
                        </Card>
                    </Grid>
                ))
                )}

            </Grid>
            {showPagination === true && vehicleData?.data?.attributes?.results?.length > 0 ? (
                <div className={classes["pagination-box"]}>
                    <Pagination
                        current={currentPagePagination}
                        defaultCurrent={currentPage}
                        defaultPageSize={currentPageSizePagination}
                        showTotal={(total) => `Total ${total} items`}
                        responsive
                        pageSize={currentPageSizePagination}
                        onChange={changePageNumber}
                        showSizeChanger={false}
                        total={vehicleData?.data?.attributes?.pagination?.total}
                    />
                </div>
            ) : (
                ""
            )}
            <Modal
                centered
                open={isModalOpen}
                onCancel={loading ? showCannotCloseNotification : handleModelCancel}
                footer={(_) => (
                    <>
                        <UIButton
                            onClick={loading ? showCannotCloseNotification : handleModelCancel}
                            variant="outlined"
                            color="error"
                            className={classes['model-buttons']}
                        >
                            Cancel
                        </UIButton>
                        <UIButton
                            onClick={handleUploadFiles}
                            variant="contained"
                            className={classes['upload-file-button']}
                            disabled={loading} // Disable the Upload button while loading
                        >
                            {loading ? <Spin indicator={<LoadingOutlined style={{ color: "#fff" }} />} /> : 'Upload'} {/* Show spinner while loading */}
                        </UIButton>
                    </>
                )}
            >
                <Upload {...props}>
                    <UIButton
                        startIcon={<UploadOutlined />}
                        className={classes['upload-file-entry']}
                        variant="outlined"
                    >
                        Browse File
                    </UIButton>
                </Upload>
                <h4>Please choose file .xls and .xlsx only. <a href={importVehicleSampleFile} download={true} className={classes["click-here-link"]}>Click here</a> to download the sample file.</h4>
                <style>{`
         & .ant-upload-list-item-actions .ant-btn {
          display: ${hideDeleteIcon ? 'none' : 'inline-block'};
        }
      `}</style>
            </Modal>

            {/* Modal popup for excel import errors display */}

            {
                excelImportErrors && Object.keys(excelImportErrors).length > 0
                    ?
                    <Modal
                        centered
                        open={isModalOpenExcelErrors}
                        onCancel={handleModelExcelErrorsCancel}
                        width={'60%'}
                        className={classes['error-import-modal']}
                        footer=""
                    >
                        <>
                            <h3 className={classes["error-import-modal-heading"]}>Please find errors in loading import excel data as below:</h3>
                            <div className={classes['error-import-excel-display-data']}>
                                <table border={0} cellPadding={0} cellSpacing={0} width={'100%'}>
                                    <thead>
                                        <tr>
                                            <th width={'8%'} align="center">Row No</th>
                                            <th width={'92%'}>Errors</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Object.entries(excelImportErrors).map(([key, value]) => {
                                                return <tr key={key}>
                                                    <td align="center"><strong>{key}</strong></td>
                                                    <td>
                                                        <table border={0} cellPadding={0} cellSpacing={0} key={key} width={'100%'}>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <ul>
                                                                            {
                                                                                value?.map((item, key) => {
                                                                                    return <li key={key}>
                                                                                        <p>{item}</p>
                                                                                    </li>
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </>
                    </Modal >
                    :
                    ""
            }
        </>
    );
};