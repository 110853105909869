import React, { useState, useEffect } from "react";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import classes from "./CreateLoading.module.scss";
import { ArrowLeftOutlined, InfoCircleOutlined, PlusOutlined, StopOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Input, DatePicker, Select, notification, Divider, Space, Modal, Tag, Upload, Checkbox } from "antd";
import AppHelper from "../../../../helpers/AppHelper";
import dayjs from "dayjs";
import moment from 'moment'
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'
import constantsArr from "../../../../constants/global-constants";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const CreateLoading = () => {
    const navigate = useNavigate();
    const [loadingForm] = Form.useForm();
    const [vehicleForm] = Form.useForm();
    const [vehicleDropDown, setVehicleDropDown] = useState([]);
    const [agencies, setAgencies] = useState([]);
    const [loadingAgencyDropDown, setLoadingAgencyDropDown] = useState([]);
    const [transportationAgencyDropDown, setTransportationAgencyDropDown] = useState([]);
    const [vehicleLists, setVehicleLists] = useState([]);
    const [netWeight, setNetWeight] = useState(0);
    const [tareWeight, setTareWeight] = useState(0);
    const [grossWeight, setGrossWeight] = useState(0);
    const sessionUserInfo = AppHelper.decryptText(sessionStorage.getItem("userInfo"));
    const sessionGlobalValues = AppHelper.decryptText(sessionStorage.getItem("globalValues"));
    const dateFormat = "DD-MM-YYYY";
    const [isSubmittingResponse, setIsSubmittingResponse] = useState(false); // New state variable
    const [newVehicleRegisterPopOpen, setNewVehicleRegisterPopOpen] = useState(false)
    const [vehicleDropDownOpen, setVehicleDropDownOpen] = useState(false)
    const [isSubmittingCreateVehicleResponse, setIsSubmittingCreateVehicleResponse] = useState(false); // New state variable
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTag, setSelectedTag] = useState(null);
    const maxNoOfUploadsAllowed = constantsArr.MAX_UPLOAD_IMAGES
    const allowedMimeTypes = constantsArr.ALLOWED_MIME_TYPES
    const maxUploadSizeAllowed = constantsArr.MAX_UPLOAD_SIZE_MB * 1024 * 1024
    const API_URL = process.env.REACT_APP_API_DOMAIN || "";
    const [uploadedFileList, setUploadedFileList] = useState([]);
    const [viewPdf, setViewPdf] = useState(null);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [fileList, setFileList] = useState([]);
    const [vehicleFetchInfo, setVehicleFetchInfo] = useState({})
    const [vehicleNoValue, setVehicleNoValue] = useState("")
    const [isForcefullyValidated, setIsForceFullyValidated] = useState(false)
    const [validatedThroughAPI, setValidatedThroughAPI] = useState(false)
    const [isVehicleInfoModelOpen, setIsVehicleInfoModelOpen] = useState(false)

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
    const [missingChallans, setMissingChallans] = useState([])
    const transform = (slot) => ({
        ...slot,
        // These slots will be empty
        EnterFullScreen: () => <></>,
        EnterFullScreenMenuItem: () => <></>,
        CurrentPageInput: () => <></>,
        NumberOfPages: () => <></>,
        MoreActionsPopover: () => <></>,
        GoToFirstPage: () => <></>,
        GoToFirstPageMenuItem: () => <></>,
        GoToNextPage: () => <></>,
        GoToNextPageMenuItem: () => <></>,
        GoToPreviousPage: () => <></>,
        GoToPreviousPageMenuItem: () => <></>,
        GoToLastPage: () => <></>,
        GoToLastPageMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
        Print: () => <></>,
        PrintMenuItem: () => <></>,
        Open: () => <></>,
        OpenMenuItem: () => <></>,
        ShowProperties: () => <></>,
        ShowPropertiesMenuItem: () => <></>,
    });

    const onFinish = async (values) => {
        setIsSubmittingResponse(true);

        let filesJson = JSON.stringify(uploadedFileList)
        const uploadImageResponse = await AppHelper.savePictureData(filesJson)
        if (uploadImageResponse?.error) {
            notification.error({
                message: "Error",
                description: uploadImageResponse?.error.message,
                duration: 3,
            });
            setIsSubmittingResponse(false);
            return false
        } else {
            const getEmailUser = sessionUserInfo?.userEmail ?? "";
            // Split the email address at the "@" symbol
            const emailParts = getEmailUser.split(".");
            // Get the part before the "@" symbol
            const username = emailParts[0];
            const capitalizedUsername = username.charAt(0).toUpperCase() + username.slice(1);
            values.Created_By = capitalizedUsername;
            // Call the postVehicle method with the form values
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
            const loadingAgency = agencies.find((item) => item?.attributes?.Company_name === values?.Loading_Agency && item?.attributes?.Project?.data?.id === globalTpProjectValues?.projectGlobal)
            const transportationAgency = agencies.find((item) => item?.attributes?.Company_name === values?.Transporter_Agency && item?.attributes?.Project?.data?.id === globalTpProjectValues?.projectGlobal)
            const vehicleDetailsByNumber = vehicleLists?.find((obj) => obj?.Vehicle_Number === values?.Vehicle_Number);
            values['Loading_Agency'] = loadingAgency?.id
            values['Project'] = { 'id': globalTpProjectValues ? globalTpProjectValues.projectGlobal : null }
            values['Thermal_Plant'] = { 'id': globalTpProjectValues ? globalTpProjectValues.thermalPlantGlobal : null }
            values['Transporter_Agency'] = transportationAgency?.id
            values['Date_of_Loading'] = dayjs(values?.Date_of_Loading).format("YYYY-MM-DD")
            values['Vehicle_Number'] = vehicleDetailsByNumber?.id
            values['Created_By'] = sessionUserInfo?.userID ?? ""
            values['Unloaded'] = false
            values['Cancelled'] = false
            values['HSD_DONE'] = false
            values['is_transporting_billed'] = false
            values['is_loading_billed'] = false
            values['is_unloading_billed'] = false
            values['is_client_billed'] = false
            values['is_bill_dependent_data_modified'] = {}
            values['is_bill_dependent_data_modified']['transational_summary'] = false
            values['is_bill_dependent_data_modified']['vehicle_list'] = false
            values['Uploaded_Pictures'] = { 'id': uploadImageResponse?.data?.id ?? "" }

            const response = await AppHelper.postLoading(values);
            if (response?.error) {
                notification.error({
                    message: "Error",
                    description: response?.error?.message,
                    duration: 3,
                });
            } else {
                notification.success({
                    message: "Success",
                    description: "Loading has been created successfully.",
                    duration: 3,
                });
                navigate("/loadings");
            }
        }
        setIsSubmittingResponse(false);
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        if (file.type === 'application/pdf') {
            // Only set viewPdf if the file is a c/PDF
            setViewPdf(file.url || file.preview);
            setPreviewOpen(true);
            if (!viewPdf) {
                setPreviewImage(null)
            }
            setPreviewTitle(
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
            );
        } else {
            setPreviewImage(file.url || file.preview);
            setPreviewOpen(true);
            if (!previewImage) {
                setViewPdf(null)
            }
            setPreviewTitle(
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
            );
        }
    };

    const onChangeCheckbox = (e) => {
        setIsForceFullyValidated(e.target.checked)
    }

    const handleChooseChallanNo = () => {
        setIsModalOpen(false);
        loadingForm.setFieldsValue({
            Challan_Number: parseInt(selectedTag),
        })
    }

    const handleCreateVehiclePopup = () => {
        setVehicleDropDownOpen(false)
        setNewVehicleRegisterPopOpen(true)
    }

    const beforeUpload = (file) => {
        // check correct file type
        const uploadefFileType = file?.type ?? ""
        if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
            notification.error({
                message: "Error",
                description: "The uploaded file is invalid; only files with the following extensions are accepted: jpg, jpeg, svg, bmp, png and pdf.",
                duration: 5
            })
            return false || Upload.LIST_IGNORE
        }
        // check for maximum size can upload
        let uploadedSize = file?.size ?? 0
        uploadedSize = parseInt(uploadedSize)
        if (uploadedSize > parseInt(maxUploadSizeAllowed)) {
            notification.error({
                message: "Error",
                description: "The uploaded file exceeds the maximum allowed image size; please ensure that the file size does not exceed 2 MB",
                duration: 5
            })
            return false || Upload.LIST_IGNORE
        }
    }

    const handleModelCancel = () => setNewVehicleRegisterPopOpen(false)

    const backToLoadingsList = (e) => {
        e.preventDefault();
        navigate("/loadings");
    };

    const disableDates = (currentDate) => {
        return currentDate > moment().endOf('day')
    }

    const loadVehicleDetails = (e) => {
        const vehicleDetailsById = vehicleLists?.find((obj) => obj?.Vehicle_Number === e);
        loadingForm.setFieldsValue({
            Owner: vehicleDetailsById?.Owner,
        });
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const onFinishCreateVehicle = async (values) => {
        setIsSubmittingCreateVehicleResponse(true);
        values.Vehicle_Information = vehicleFetchInfo ?? {}
        values.Gross_Weight = vehicleFetchInfo?.grossVehicleWeight ?? 0
        values.Vehicle_Category = vehicleFetchInfo?.vehicleCategory ?? ""
        values.Forcefully_Validated = isForcefullyValidated ?? false
        values.Validated_Through_API = validatedThroughAPI ?? false

        const vehicle = await AppHelper.postVehicle(values);
        // Optionally, you can show a success message
        if (vehicle?.error) {
            notification.error({
                message: "Error",
                description: vehicle?.error?.message,
                duration: 1.5,
            });
        } else {
            notification.success({
                message: "Success",
                description: "Vehicle created successfully.",
                duration: 1.5,
            });
            setNewVehicleRegisterPopOpen(false)
            // add newly created vehicle no on top of drop down menu
            setVehicleDropDown([{ 'value': values?.Vehicle_Number, 'label': values?.Vehicle_Number }, ...vehicleDropDown])
            // add newly added vehicle no in main loaded vehicle list 
            setVehicleLists([
                {
                    "id": vehicle?.data?.id,
                    "Vehicle_Number": vehicle?.data?.attributes?.Vehicle_Number,
                    "Owner": vehicle?.data?.attributes?.Owner,
                    "No_of_Tyres": vehicle?.data?.attributes?.No_of_Tyres,
                },
                ...vehicleLists
            ])
            setVehicleDropDownOpen(false)
            // set newly created vehicle no to vehicle number field automatically
            loadingForm.setFieldsValue({
                Vehicle_Number: values?.Vehicle_Number,
                Owner: values?.Owner
            })

        }
        setIsSubmittingCreateVehicleResponse(false);
    }

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const handleKeyDown = (event) => {
        // Check if the pressed key is an alphabet character
        if (/[a-zA-Z]/.test(event.key) && event.key !== 'Backspace') {
            event.preventDefault(); // Prevent input of alphabets
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedTag(null);
        setPreviewOpen(false);
    };

    const handleCancelVehicleModelOpen = () => {
        setIsVehicleInfoModelOpen(false);
    };

    const handleTagClick = (index) => {
        setSelectedTag(index);
    };

    const customRequest = async ({ file, onSuccess, onError }) => {
        const uploadefFileType = file?.type ?? ""
        if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
            return false
        }
        const response = await AppHelper.uploadImages(file);
        if (response?.error) {
            onError(new Error("Upload failed"));
        } else {
            onSuccess();
            let imageResponse = response[0]
            Object.entries(imageResponse).forEach(([key, value]) => {
                if (key === 'url') {
                    if (uploadefFileType === 'application/pdf') {
                        // Do not set viewPdf here
                    }
                    imageResponse[key] = API_URL + value
                    imageResponse['uid'] = file?.uid
                }
            })
            uploadedFileList.push({ ...imageResponse });
        }
    };

    const handleChange = async (values) => {
        setIsSubmittingResponse(false);
        const fileStatus = values?.file?.status
        if (fileStatus !== 'removed') {
            const uploadefFileType = values?.file?.type ?? ""
            if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
                return false || Upload.LIST_IGNORE
            } else {
                // check for maximum size can upload
                let uploadedSize = values?.file?.size ?? 0
                uploadedSize = parseInt(uploadedSize)
                if (uploadedSize > parseInt(maxUploadSizeAllowed)) {
                    return false || Upload.LIST_IGNORE
                } else {
                    if (values?.file?.type === 'application/pdf') {
                        const getUploadedFile = values?.fileList.find((item) => item?.uid === values?.file?.uid)
                        let fileReader = new FileReader();
                        // Onload of file read the file content
                        fileReader.onload = function (fileLoadedEvent) {
                            // file = fileLoadedEvent.target.result;
                            // Print data in console
                            getUploadedFile.thumbUrl = fileLoadedEvent.target.result
                        };
                        // Convert data to base64
                        fileReader.readAsDataURL(values?.file?.originFileObj);
                    }
                }
            }
        } else {
            // remove from uploaded file array
            const uploadedFileListUpdated = uploadedFileList.filter((item) => item?.uid !== values?.file?.uid)
            setUploadedFileList(uploadedFileListUpdated)
        }
        setFileList(values?.fileList);
    };

    const validateVehicleNumber = async () => {
        if (!vehicleNoValue || vehicleNoValue === '') {
            notification.error({
                message: "Error",
                description: "Please enter vehicle number",
                duration: 3,
            });
            return false
        }
        const getVehicleInformation = await AppHelper.validateVehicleNo(vehicleNoValue)
        if (getVehicleInformation?.error) {
            notification.error({
                message: "Error",
                description: getVehicleInformation?.error?.message,
                duration: 3,
            });
        } else {
            setValidatedThroughAPI(true)
            setVehicleFetchInfo(getVehicleInformation?.result ?? getVehicleInformation ?? {})
            setIsVehicleInfoModelOpen(true)
        }
    };

    const handleVehicleChange = (e) => {
        setVehicleNoValue(e.target.value)
    }

    useEffect(() => {
        // Load fileList from sessionStorage when the component mounts

        // Fetch vehicle and agency data
        AppHelper.getVehiclesWithoutPagination().then((response) => {
            setVehicleLists(response?.data);
            setVehicleDropDown(AppHelper.getVehicleNumberDropDownWithoutPagination(response?.data));
        });

        AppHelper.getAgenciesByProject(sessionGlobalValues?.projectGlobal).then((response) => {
            setAgencies(response)
            setLoadingAgencyDropDown(AppHelper.getAgencyDropDown(response, 'Loading', sessionGlobalValues));
            setTransportationAgencyDropDown(AppHelper.getAgencyDropDown(response, 'Transportation', sessionGlobalValues));
        });

        AppHelper.getMissingChallans().then((response) => {
            setMissingChallans(response?.data);
        });

        vehicleForm.setFieldsValue({
            No_of_Tyres: 0
        })
    }, []);

    useEffect(() => {
        document.title = "Create Loading"
        const updatedGrossWeight = ((parseFloat(netWeight) + parseFloat(tareWeight)).toFixed(2)).toString();
        setGrossWeight(updatedGrossWeight);
        loadingForm.setFieldsValue({ Gross_Weight: updatedGrossWeight });
    }, [netWeight, tareWeight]);

    useEffect(() => {
        // Set initial values for the form fields when the component mounts
        loadingForm.setFieldsValue({ Date_of_Loading: dayjs() });
    }, [loadingForm]);

    return (
        <>
            <Grid
                container
                spacing={0}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["create-loading-padding"]}
            >
                <Grid item lg={10} md={9} xs={12}>
                    <Typography
                        variant="h1"
                        fontSize="1.5rem"
                        fontWeight="bold"
                        className="title-un-loading-text"
                    >
                        Create Loading
                    </Typography>
                </Grid>
                <Grid item lg={2} md={3} xs={12} container className={classes['action-button']}>
                    <UIButton
                        size="small"
                        variant="contained"
                        onClick={backToLoadingsList}
                        className={classes["add-new-entry"]}
                    >
                        <ArrowLeftOutlined />
                        &nbsp;Loadings
                    </UIButton>
                </Grid>
            </Grid>
            <div className={classes["agency-details-margin"]}>
                <Form layout="vertical" onFinish={onFinish} form={loadingForm}>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="wrap"
                    >
                        <Grid item lg={6} md={6} xs={12} className={classes["position-relative"]}>
                            <Form.Item
                                label="Challan No." name="Challan_Number"
                                rules={[
                                    { required: true, message: "Please enter the Challan No." },
                                ]}
                            >
                                <Input type="number" onKeyDown={AppHelper.preventMinusDot} className={classes['width95']} />
                            </Form.Item>
                            <div className={classes["input-field-challan-button"]}>
                                <StopOutlined className={classes["stop-missing-icon"]} onClick={showModal} />
                            </div>
                            <Form.Item label="Vehicle Number" name="Vehicle_Number"
                                rules={[
                                    { required: true, message: "Please enter the Vehicle No." },
                                ]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={filterOption}
                                    style={{ width: "100%" }}
                                    onChange={loadVehicleDetails}
                                    placeholder="Select"
                                    open={vehicleDropDownOpen}
                                    onDropdownVisibleChange={(visible) => setVehicleDropDownOpen(visible)}
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            <Divider
                                                style={{
                                                    margin: '8px 0',
                                                }}
                                            />
                                            <Space
                                                style={{
                                                    padding: '0 8px 4px',
                                                }}
                                            >
                                                <UIButton type="text" icon={<PlusOutlined />} className={classes["add-new-vehicle-dropdown"]} onClick={handleCreateVehiclePopup}>
                                                    Add New Vehicle Number
                                                </UIButton>
                                            </Space>
                                        </>
                                    )}
                                    options={vehicleDropDown}
                                />
                            </Form.Item>
                            <Form.Item label="Loading Agency" name="Loading_Agency"
                                rules={[
                                    { required: true, message: "Please enter the Loading Agency" },
                                ]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={filterOption}
                                    options={loadingAgencyDropDown}
                                    placeholder="Select"
                                />
                            </Form.Item>
                            <Form.Item label="Transporting Agency" name="Transporter_Agency"
                                rules={[
                                    { required: true, message: "Please enter the Transporting Agency" },
                                ]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={filterOption}
                                    style={{ width: "100%" }}
                                    options={transportationAgencyDropDown}
                                    placeholder="Select"
                                />
                            </Form.Item>
                            <Form.Item label="Net Weight at Loading (Kg)" name="Net_Weight">
                                <Input
                                    onKeyDown={AppHelper.preventMinus}
                                    min={0}
                                    onChange={(e) => setNetWeight(parseFloat(e.target.value) || 0)}
                                    type="number"
                                    step="any"
                                />
                            </Form.Item>
                            <Form.Item
                                label="Gross weight at Loading (Kg)"
                                name="Gross_Weight"
                                dependencies={["Net_Weight", "Tare_Weight"]}
                            >
                                <Input
                                    onKeyDown={AppHelper.preventMinus}
                                    value={grossWeight}
                                    readOnly
                                    type="number"
                                    min={0}
                                    step="any"
                                    disabled
                                    className={classes['disabled-input']}
                                />
                            </Form.Item>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                            <Form.Item
                                label="Date of Loading"
                                name="Date_of_Loading"
                                rules={[
                                    { required: true, message: "Please select Date of Loading" },
                                ]}>
                                <DatePicker
                                    style={{ width: "100%" }}
                                    disabledDate={disableDates}
                                    picker="date"
                                    format={dateFormat}
                                />
                            </Form.Item>
                            <Form.Item label="Owner" name="Owner">
                                <Input readOnly disabled className={classes['disabled-input']} />
                            </Form.Item>
                            <Form.Item
                                label="Thermal Plant Book No."
                                name="Thermal_Plant_Book_no"
                                rules={[
                                    { required: true, message: "Please enter the Thermal Plant Book No." },
                                ]}
                            >
                                <Input onKeyDown={handleKeyDown} />
                            </Form.Item>
                            <Form.Item label="Kanta Slip No. at Loading" name="Kanta_Slip_No">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Tare weight at Loading (Kg)" name="Tare_Weight">
                                <Input
                                    step="any"
                                    onKeyDown={AppHelper.preventMinus}
                                    min={0}
                                    onChange={(e) => setTareWeight(parseFloat(e.target.value) || 0)}
                                    type="number"
                                />
                            </Form.Item>
                            <Form.Item
                                name="DO_No"
                                label="D/O No."
                                wrapperCol={{ span: 24 }}
                                className={classes['ant-form-item']}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter the D/O Number"
                                    },
                                    {
                                        validator: async (_, value) => {
                                            if (!value) return;
                                            if (/^[a-zA-Z0-9]+$/.test(value)) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject(new Error(`D/O No must be alphanumeric only`));
                                            }
                                        },
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>

                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                        className={classes["grid-position"]}
                    >
                        <Grid item lg={6} md={6} xs={12}>
                            <Form.Item label="Employee at Loading Point" name="Loading_by_Employee">
                                <Input />
                            </Form.Item>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                            <Form.Item name="Remarks" label="Remarks">
                                <Input type="text" />
                            </Form.Item>
                        </Grid>
                    </Grid>

                    {/* <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                        className={classes["grid-position"]}
                    >
                        <Grid item lg={6} md={6} xs={12}>
                            <Form.Item label="Loading Agency Rate Type" name="Loading_Rate.Rate_type">
                                <Input readOnly disabled className={classes['disabled-input']} />
                            </Form.Item>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                            <Form.Item label="Loading Agency Rate" name="Loading_Rate.Rate">
                                <Input type="number" />
                            </Form.Item>
                        </Grid>
                    </Grid> */}

                    {/* <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                        className={classes["grid-position"]}
                    >
                        <Grid item lg={6} md={6} xs={12}>
                            <Form.Item label="Transportation Agency Rate Type" name="Transportation_Rate.Rate_type">
                                <Input readOnly disabled className={classes['disabled-input']} />
                            </Form.Item>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                            <Form.Item label="Transportation Agency Rate" name="Transportation_Rate.Rate">
                                <Input type="number" />
                            </Form.Item>
                        </Grid>
                    </Grid> */}

                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                        className={classes["grid-position"]}
                    >
                        <Grid item lg={12} md={12} xs={12} className={classes["grid-fixed"]}>
                            <Upload
                                customRequest={customRequest}
                                multiple
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChange}
                                name="Unloading_Images"
                                withCredentials={true}
                                beforeUpload={beforeUpload}
                                accept="image/png, image/jpeg, image/svg+xml, image/bmp, image/x-png, application/pdf"
                            >
                                {fileList.length >= maxNoOfUploadsAllowed ? null : uploadButton}
                            </Upload>
                            <Modal
                                open={previewOpen}
                                title={previewTitle}
                                footer={null}
                                onCancel={handleCancel}
                                width={800}
                                styles={{ body: { height: '500px', overflowY: 'scroll', maxHeight: '500px' } }}
                            >
                                <div
                                    className="rpv-core__viewer"
                                    style={{
                                        border: '1px solid rgba(0, 0, 0, 0.3)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                    }}
                                >
                                    {viewPdf && (
                                        <>
                                            <div
                                                style={{
                                                    alignItems: 'center',
                                                    backgroundColor: '#eeeeee',
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                                    display: 'flex',
                                                    padding: '0.25rem',
                                                }}
                                            >
                                                <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
                                            </div>
                                            <>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.js`}>
                                                        <Viewer fileUrl={viewPdf} plugins={[toolbarPluginInstance]} />
                                                    </Worker>
                                                </div>
                                            </>
                                        </>
                                    )}
                                    {previewImage && ( // Render image if not viewing PDF
                                        <img
                                            alt="example"
                                            style={{
                                                width: "100%",
                                            }}
                                            src={previewImage}
                                        />
                                    )}
                                </div>
                            </Modal>
                        </Grid>
                    </Grid>
                    <div className={classes["submit-button-area"]}>
                        <UIButton variant="contained" color="primary" type="submit" className={classes['submit-button']} disabled={isSubmittingResponse}>
                            Save
                        </UIButton>
                        <UIButton
                            variant="outlined"
                            color="error"
                            onClick={backToLoadingsList}
                        >
                            Cancel
                        </UIButton>
                    </div>
                </Form>

                <Modal open={isModalOpen} footer={false} onCancel={handleCancel} centered className={classes["model-missing-challan"]} width={800}>
                    {
                        missingChallans?.length > 0
                            ?
                            <>
                                <Typography variant="h6" color="initial" className={classes["missing-challan-heading"]}>Missing Challan ({missingChallans?.length ?? 0})</Typography>
                                <div className={classes["missing-tags"]} style={{ height: 400, overflowY: 'scroll' }}>
                                    {missingChallans.map((value, index) => (
                                        <Tag
                                            key={index}
                                            className={`${classes["tag-challan"]} ${selectedTag === value?.attributes?.missing_challans ? classes["selected"] : ""}`}
                                            onClick={() => handleTagClick(value?.attributes?.missing_challans)}
                                        >
                                            {value?.attributes?.missing_challans}
                                        </Tag>
                                    ))}
                                </div>

                                <div className={classes["flex-items-missing"]}>
                                    <Typography variant="body2" color="initial" className={classes["description-missing-challan"]}>
                                        <InfoCircleOutlined /> Click on The No. To use Missing challan No.
                                    </Typography>
                                    <UIButton variant="contained" className={classes["use-challan-no-btn"]} onClick={handleChooseChallanNo}>Use Challan No.</UIButton>
                                </div>
                            </>
                            :
                            <Typography variant="h6" color="initial" className={classes["missing-challan-heading"]}>No Missing Challan is available</Typography>
                    }
                </Modal>
            </div>
            {
                newVehicleRegisterPopOpen
                    ?
                    <Modal
                        centered
                        open={newVehicleRegisterPopOpen}
                        onCancel={handleModelCancel}
                        width={'50%'}
                        className={classes['error-import-modal']}
                        footer=""
                    >
                        <>
                            <h3 className={classes["error-import-modal-heading"]}>Create New Vehicle</h3>
                            <div className={classes['error-import-excel-display-data']}>
                                <Form layout="vertical" form={vehicleForm} onFinish={onFinishCreateVehicle}>
                                    <Grid
                                        container
                                        spacing={1}
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        alignContent="center"
                                        wrap="wrap"
                                        className={classes["grid-space"]}
                                    >
                                        <Grid item lg={6} md={6} xs={12} className={classes["position-relative"]}>
                                            <Form.Item
                                                label="Vehicle Number"
                                                name="Vehicle_Number"
                                                rules={[
                                                    {
                                                        required: true, message: "Please enter the vehicle number"
                                                    },
                                                    {
                                                        pattern: new RegExp(/^[A-Z]{2}[0-9]{2}[A-Z]{1,2}[0-9]{4}$/i),
                                                        message: "Please enter valid vehicle number"
                                                    }
                                                ]}
                                            >
                                                <Input className={classes['width88']} onChange={handleVehicleChange} />
                                            </Form.Item>
                                            <div className={classes["input-field-challan-button-validate"]}>
                                                <Typography className={classes["stop-missing-icon"]} onClick={validateVehicleNumber}>Validate</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item lg={6} md={6} xs={12}>
                                            <Form.Item
                                                label="Owner"
                                                name="Owner"
                                                rules={[
                                                    {
                                                        required: true, message: "Please enter the Owner Name"
                                                    }
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={1}
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        alignContent="center"
                                        wrap="wrap"
                                        className={classes["grid-space"]}
                                    >
                                        <Grid item lg={6} md={6} xs={12}>
                                            <Form.Item name="No_of_Tyres" label="No of Tyres"
                                                rules={[
                                                    {
                                                        pattern: new RegExp(/^[0-9]+$/),
                                                        message: "No of Tyres should be integer only"
                                                    }
                                                ]}>
                                                <Input type="number" min={0} max={20} />
                                            </Form.Item>
                                        </Grid>
                                        <Grid item lg={6} md={6} xs={12}>
                                            <Form.Item name="Remarks" label="Remarks">
                                                <Input type="text" />
                                            </Form.Item>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={1}
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        alignContent="center"
                                        wrap="nowrap"
                                        className={classes["grid-space"]}
                                    >
                                        <Grid item lg={12} md={12} xs={12}>
                                            <Form.Item
                                                messageVariables=""
                                                wrapperCol={{ span: 30 }}
                                                labelCol={{ span: 30 }}
                                                className={classes['ant-form-item-radio']}
                                            >
                                                <Checkbox value={true} onChange={onChangeCheckbox}>Forcefully Validated</Checkbox>
                                            </Form.Item>
                                        </Grid>
                                    </Grid>
                                    <div className={classes["submit-button-area"]}>
                                        <UIButton variant="contained" color="primary" type="submit" className={classes['submit-button']} disabled={isSubmittingCreateVehicleResponse}>
                                            Save
                                        </UIButton>
                                        <UIButton
                                            variant="outlined"
                                            color="error"
                                            onClick={handleModelCancel}
                                        >
                                            Cancel
                                        </UIButton>
                                    </div>
                                </Form >
                            </div>
                        </>
                    </Modal >
                    :
                    ""
            }
            <Modal open={isVehicleInfoModelOpen} footer={false} onCancel={handleCancelVehicleModelOpen} centered className={classes["model-missing-challan"]} width={1000}>
                {
                    Object.keys(vehicleFetchInfo)?.length > 0
                        ?
                        <>
                            <Typography variant="h6" color="initial" className={classes["missing-challan-heading"]}>Vehicle Details</Typography>
                            <div className={classes["missing-tags"]} style={{ height: 400, overflowY: 'scroll' }}>
                                {
                                    Object.entries(vehicleFetchInfo).filter(item => item?.[0] !== 'splitPresentAddress' && item?.[0] !== 'splitPermanentAddress').map(([value, key], i) => {
                                        return <>
                                            <div className={classes['vehicle-info-row']} style={{ width: constantsArr.LONG_LABELS_VEHICLE_INFO_TABLE.includes(value) ? '100%' : '49%' }}>
                                                <span className={classes['label']}>
                                                    {AppHelper.convertStringToLabel(value)}:
                                                </span>
                                                {(!key || key === '') ? 'NA' : key}
                                            </div>
                                            {++i % 2 === 0 ? <div className={classes['clear-both']}></div> : ""}
                                        </>
                                    })
                                }
                            </div>

                            <div className={classes["flex-items-missing"]}>
                                <Typography variant="body2" color="initial" className={classes["description-missing-challan"]}>

                                </Typography>
                            </div>
                        </>
                        :
                        <Typography variant="h6" color="initial" className={classes["missing-challan-heading"]}>No Vehicle information is available</Typography>
                }
            </Modal>
        </>
    );
};
