import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import classes from "./CreateUnloadedData.module.scss";
import { Form, Input, DatePicker, Select, Upload, Modal, notification } from "antd";
import AppHelper from "../../../../helpers/AppHelper";
import dayjs from "dayjs";
import constantsArr from "../../../../constants/global-constants";
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const CreateUnloadedData = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loadingForm] = Form.useForm();
    const loadId = params?.id ?? "";
    const [loadDetails, setLoadDetails] = useState("");
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [fileList, setFileList] = useState([]);
    const [netWeight, setNetWeight] = useState(0);
    const [tareWeight, setTareWeight] = useState(0);
    const [unloadingAgencyLists, setunloadingAgencyLists] = useState([]);
    const [vehicleLists, setVehicleLists] = useState([]);
    const [unloadingAgencyDropDown, setUnLoadingAgencyDropDown] = useState([]);
    const [transportationAgencyDropDown, setTransportationAgencyDropDown] = useState([]);
    const sessionGlobalValues = AppHelper.decryptText(sessionStorage.getItem("globalValues"));
    const sessionUserInfo = AppHelper.decryptText(sessionStorage.getItem("userInfo"));
    const maxNoOfUploadsAllowed = constantsArr.MAX_UPLOAD_IMAGES
    const allowedMimeTypes = constantsArr.ALLOWED_MIME_TYPES
    const maxUploadSizeAllowed = constantsArr.MAX_UPLOAD_SIZE_MB * 1024 * 1024
    const API_URL = process.env.REACT_APP_API_DOMAIN || "";
    const [uploadedFileList, setUploadedFileList] = useState([]);
    const [viewPdf, setViewPdf] = useState(null);
    const dateFormat = "DD-MM-YYYY";
    const [isSubmittingResponse, setIsSubmittingResponse] = useState(false);

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
    const transform = (slot) => ({
        ...slot,
        // These slots will be empty
        EnterFullScreen: () => <></>,
        EnterFullScreenMenuItem: () => <></>,
        CurrentPageInput: () => <></>,
        NumberOfPages: () => <></>,
        MoreActionsPopover: () => <></>,
        GoToFirstPage: () => <></>,
        GoToFirstPageMenuItem: () => <></>,
        GoToNextPage: () => <></>,
        GoToNextPageMenuItem: () => <></>,
        GoToPreviousPage: () => <></>,
        GoToPreviousPageMenuItem: () => <></>,
        GoToLastPage: () => <></>,
        GoToLastPageMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
        Print: () => <></>,
        PrintMenuItem: () => <></>,
        Open: () => <></>,
        OpenMenuItem: () => <></>,
        ShowProperties: () => <></>,
        ShowPropertiesMenuItem: () => <></>,
    });

    const getLoadingDetails = async () => {
        const response = await AppHelper.getLoadingById(loadId);
        setFormDefaultValues(response)
        setNetWeight(response?.data?.attributes?.Net_Weight ?? 0)
        setTareWeight(response?.data?.attributes?.Tare_Weight ?? 0)
        setLoadDetails(response.data);
    };

    const setFormDefaultValues = (data) => {
        loadingForm.setFieldsValue({
            Date_of_Unloading: dayjs(data?.data?.attributes?.Date_of_Loading),
            Challan_Number: data?.data?.attributes?.Challan_Number,
            Vehicle_Number: data?.data?.attributes?.Vehicle_Number?.data?.attributes?.Vehicle_Number,
            Owner: data?.data?.attributes?.Vehicle_Number?.data?.attributes?.Owner,
            Transporter_Agency: data?.data?.attributes?.Transporter_Agency?.data?.attributes?.Company_name,
            Thermal_Plant_Book_no: data?.data?.attributes?.Thermal_Plant_Book_no,
            Net_Weight: data?.data?.attributes?.Net_Weight,
            Tare_Weight: data?.data?.attributes?.Tare_Weight,
            Gross_Weight: data?.data?.attributes?.Gross_Weight,
            Kanta_Slip_No: data?.data?.attributes?.Kanta_Slip_No ?? "No"
        })
    }

    const onFinish = async (values) => {
        setIsSubmittingResponse(true);
        // upload images and set reference to unloading record
        if (uploadedFileList.length === 0) {
            notification.error({
                message: "Error",
                description: "Please upload images of unloading",
                duration: 3,
            });
            setIsSubmittingResponse(false);
            return false
        }
        let filesJson = JSON.stringify(uploadedFileList)
        const uploadImageResponse = await AppHelper.savePictureData(filesJson)
        if (uploadImageResponse?.error) {
            notification.error({
                message: "Error",
                description: uploadImageResponse?.error.message,
                duration: 3,
            });
            setIsSubmittingResponse(false);
            return false
        } else {
            // check that unloading date is not less than loading date
            const dateUnloading = new Date(dayjs(values.Date_of_Unloading).format("YYYY-MM-DD"))
            const dateLoading = new Date(dayjs(loadDetails?.attributes?.Date_of_Loading).format("YYYY-MM-DD"))
            if (dateUnloading < dateLoading) {
                notification.error({
                    message: "Error",
                    description: "Date of Unloading can not be less than loading date",
                    duration: 3,
                });
                setIsSubmittingResponse(false);
                return false
            }
            // Call the postVehicle method with the form values
            const unloadingAgency = unloadingAgencyLists.find((item) => item?.attributes?.Company_name === values?.Unloading_Agency && item?.attributes?.Project?.data?.id === sessionGlobalValues?.projectGlobal)
            const transportationAgency = unloadingAgencyLists.find((item) => item?.attributes?.Company_name === values?.Transporter_Agency && item?.attributes?.Project?.data?.id === sessionGlobalValues?.projectGlobal)
            const vehicleDetailsByNumber = vehicleLists?.data?.find((obj) => obj?.Vehicle_Number === values?.Vehicle_Number);
            values['Unloading_Agency'] = unloadingAgency?.id
            values['Transporting_Agency'] = transportationAgency?.id
            values['Project'] = {
                id: sessionGlobalValues?.projectGlobal ?? ""
            }
            values['Thermal_Plant'] = sessionGlobalValues?.thermalPlantGlobal ?? ""
            values['Date_of_Unloading'] = dayjs(values.Date_of_Unloading).format("YYYY-MM-DD")
            values['Challan_Number'] = loadDetails?.attributes?.Challan_Number
            values['Vehicle_Number'] = vehicleDetailsByNumber?.id
            values['Loading'] = loadDetails?.id
            values['Created_By'] = sessionUserInfo?.userID ?? ""
            values['Uploaded_Pictures'] = { 'id': uploadImageResponse?.data?.id ?? "" }
            const response = await AppHelper.postUnLoading(values);
            // Optionally, you can show a success message
            if (response?.error) {
                notification.error({
                    message: "Error",
                    description: response?.error.message,
                    duration: 3,
                });
                setIsSubmittingResponse(false);
            } else {
                notification.success({
                    message: "Success",
                    description: "Unloading has been created successfully.",
                    duration: 3,
                });
                setIsSubmittingResponse(false);
                navigate("/loadings?sort=updatedAt:desc");
            }
        }
    };

    const backToloadingsList = (e) => {
        e.preventDefault();
        navigate("/loadings");
    };
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        if (file.type === 'application/pdf') {
            // Only set viewPdf if the file is a c/PDF
            setViewPdf(file.url || file.preview);
            setPreviewOpen(true);
            if (!viewPdf) {
                setPreviewImage(null)
            }
            setPreviewTitle(
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
            );
        } else {
            setPreviewImage(file.url || file.preview);
            setPreviewOpen(true);
            if (!previewImage) {
                setViewPdf(null)
            }
            setPreviewTitle(
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
            );
        }
    };

    const customRequest = async ({ file, onSuccess, onError }) => {
        const uploadefFileType = file?.type ?? ""
        if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
            return false
        }
        const response = await AppHelper.uploadImages(file);
        if (response?.error) {
            onError(new Error("Upload failed"));
        } else {
            onSuccess();
            let imageResponse = response[0]
            Object.entries(imageResponse).forEach(([key, value]) => {
                if (key === 'url') {
                    if (uploadefFileType === 'application/pdf') {
                        // Do not set viewPdf here
                    }
                    imageResponse[key] = API_URL + value
                    imageResponse['uid'] = file?.uid
                }
            })
            uploadedFileList.push({ ...imageResponse });
        }
    };

    const beforeUpload = (file) => {
        // check correct file type
        const uploadefFileType = file?.type ?? ""
        if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
            notification.error({
                message: "Error",
                description: "The uploaded file is invalid; only files with the following extensions are accepted: jpg, jpeg, svg, bmp, png and pdf.",
                duration: 5
            })
            return false || Upload.LIST_IGNORE
        }
        // check for maximum size can upload
        let uploadedSize = file?.size ?? 0
        uploadedSize = parseInt(uploadedSize)
        if (uploadedSize > parseInt(maxUploadSizeAllowed)) {
            notification.error({
                message: "Error",
                description: "The uploaded file exceeds the maximum allowed image size; please ensure that the file size does not exceed 2 MB",
                duration: 5
            })
            return false || Upload.LIST_IGNORE
        }
    }

    const handleChange = async (values) => {
        setIsSubmittingResponse(false);
        const fileStatus = values?.file?.status
        if (fileStatus !== 'removed') {
            const uploadefFileType = values?.file?.type ?? ""
            if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
                return false || Upload.LIST_IGNORE
            } else {
                // check for maximum size can upload
                let uploadedSize = values?.file?.size ?? 0
                uploadedSize = parseInt(uploadedSize)
                if (uploadedSize > parseInt(maxUploadSizeAllowed)) {
                    return false || Upload.LIST_IGNORE
                } else {
                    if (values?.file?.type === 'application/pdf') {
                        const getUploadedFile = values?.fileList.find((item) => item?.uid === values?.file?.uid)
                        let fileReader = new FileReader();
                        // Onload of file read the file content
                        fileReader.onload = function (fileLoadedEvent) {
                            // file = fileLoadedEvent.target.result;
                            // Print data in console
                            getUploadedFile.thumbUrl = fileLoadedEvent.target.result
                        };
                        // Convert data to base64
                        fileReader.readAsDataURL(values?.file?.originFileObj);
                    }
                }
            }
        } else {
            // remove from uploaded file array
            const uploadedFileListUpdated = uploadedFileList.filter((item) => item?.uid !== values?.file?.uid)
            setUploadedFileList(uploadedFileListUpdated)
        }
        setFileList(values?.fileList);
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const handleSelectInputChange = (e, type) => {
        setLoadDetails((prevDetails) => {
            const newDetails = {
                ...prevDetails,
                attributes: {
                    ...prevDetails.attributes,
                    [type]: e,
                },
            };
            return newDetails;
        });
    };

    const handleInputChange = (e) => {
        setIsSubmittingResponse(false);
        const { id, value } = e.target;
        if (id === "Tare_Weight") {
            setTareWeight(Math.abs(parseFloat(value) || 0));
            loadingForm.setFieldsValue({ Gross_Weight: ((parseFloat(netWeight) + parseFloat(tareWeight)).toFixed(2)).toString() });
        } else if (id === "Net_Weight") {
            setNetWeight(Math.abs(parseFloat(value)) || 0)
            loadingForm.setFieldsValue({ Gross_Weight: ((parseFloat(netWeight) + parseFloat(tareWeight)).toFixed(2)).toString() });
        }
    };

    const onChangeDate = () => {
        setIsSubmittingResponse(false);
    }

    const onChangeUnloadingAgencyDropDown = () => {
        setIsSubmittingResponse(false);
    }

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    useEffect(() => {
        getLoadingDetails();
        AppHelper.getVehiclesWithoutPagination().then((response) => {
            setVehicleLists(response);
        });

        AppHelper.getAgenciesByProject(sessionGlobalValues?.projectGlobal).then((response) => {
            setunloadingAgencyLists(response);
            setTransportationAgencyDropDown(AppHelper.getAgencyDropDown(response, 'Transportation', sessionGlobalValues));
            setUnLoadingAgencyDropDown(AppHelper.getAgencyDropDown(response, 'Unloading', sessionGlobalValues));
        });

        setNetWeight(loadDetails?.attributes?.Net_Weight ?? 0)
        setTareWeight(loadDetails?.attributes?.Tare_Weight ?? 0)
    }, []);

    useEffect(() => {
        document.title = "Create Unloading"
        const updatedGrossWeight = ((parseFloat(netWeight) + parseFloat(tareWeight)).toFixed(2)).toString();
        loadingForm.setFieldsValue({ Gross_Weight: updatedGrossWeight });
    }, [netWeight, tareWeight]);

    return (
        <>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["create-unloading-padding"]}
            >
                <Grid item lg={10} md={9} xs={12}>
                    <Typography
                        variant="h1"
                        fontSize="1.5rem"
                        fontWeight="bold"
                    >
                        Create Unloading (Challan No: {loadDetails?.attributes?.Challan_Number ?? "-"})
                    </Typography>
                </Grid>
                <Grid item lg={2} md={3} xs={12} container className={classes['action-button']}>
                    <UIButton
                        size="small"
                        variant="contained"
                        onClick={backToloadingsList}
                        className={classes['add-new-entry']}
                    >
                        <ArrowLeftOutlined />
                        &nbsp;Loadings
                    </UIButton>
                </Grid>
            </Grid>
            <div className={classes["agency-details-margin"]}>
                <Form layout="vertical" onFinish={onFinish} form={loadingForm}>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="wrap"
                    >
                        <Grid item lg={6} md={6} sm={12}>
                            <Form.Item messageVariables="" label="Challan No." name="Challan_Number">
                                <Input disabled className={classes['disabled-input']} />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Vehicle Number" name="Vehicle_Number">
                                <Input disabled className={classes['disabled-input']} />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Thermal Plant Book No." name="Thermal_Plant_Book_no">
                                <Input
                                    disabled
                                    className={classes['disabled-input']}
                                    onChange={(e) => handleSelectInputChange(e, "thermal")}
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Unloading Kanta Slip No." name="Kanta_Slip_No" rules={[
                                { required: true, message: "Please enter unloading kanta slip no." },
                            ]}>
                                <Input onChange={handleInputChange} value="No" />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Tare Weight at Unloading (Kg)" name="Tare_Weight" rules={[
                                { required: true, message: "Please enter tare weight at unloading (Kg)" },
                            ]}>
                                <Input
                                    type="number"
                                    min={0}
                                    onChange={handleInputChange}
                                    onKeyDown={AppHelper.preventMinus}
                                    id="Tare_Weight"
                                    step="any"
                                    readOnly={false}
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Unloading Agency" name="Unloading_Agency" rules={[
                                { required: true, message: "Please select unloading agency" },
                            ]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={filterOption}
                                    style={{ width: "100%" }}
                                    options={unloadingAgencyDropDown}
                                    onChange={onChangeUnloadingAgencyDropDown}
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Employee at Unloading Point" name="Unloading_by_Employee">
                                <Input onChange={handleInputChange} />
                            </Form.Item>
                            {/* <Form.Item label="Unloading Agency Rate Type" name="Unloading_Rate.Rate_type">
                                <Input readOnly disabled className={classes['disabled-input']} />
                            </Form.Item> */}
                        </Grid>
                        <Grid item lg={6} md={6} sm={12}>
                            <Form.Item messageVariables="" label="Date of Unloading" name="Date_of_Unloading"
                                rules={[
                                    { required: true, message: "Please select Date of Unloading" },
                                ]}>
                                <DatePicker
                                    style={{ width: "100%" }}
                                    picker="date"
                                    format={dateFormat}
                                    onChange={onChangeDate}
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Owner" name="Owner">
                                <Input
                                    disabled
                                    className={classes['disabled-input']}
                                    onChange={(e) => handleSelectInputChange(e, "owner")}
                                />
                            </Form.Item>

                            <Form.Item messageVariables="" label="Transporting Agency" name="Transporter_Agency">
                                <Select
                                    showSearch
                                    disabled
                                    className={classes['disabled-input']}
                                    optionFilterProp="children"
                                    filterOption={filterOption}
                                    options={transportationAgencyDropDown}
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Net Weight at Unloading (Kg)" name="Net_Weight" rules={[
                                { required: true, message: "Please enter net weight at unloading (Kg)" },
                            ]}>
                                <Input
                                    type="number"
                                    min={0}
                                    step="any"
                                    onChange={handleInputChange}
                                    onKeyDown={AppHelper.preventMinus}
                                    id="Net_Weight"
                                    readOnly={false}
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Gross Weight at Unloading (Kg)" name="Gross_Weight" rules={[
                                { required: true, message: "Please enter gross weight at unloading (Kg)" },
                            ]}>
                                <Input
                                    type="number"
                                    min={0}
                                    step="any"
                                    onKeyDown={AppHelper.preventMinus}
                                    onChange={handleInputChange}
                                    readOnly={true}
                                    disabled
                                    className={classes['disabled-input']}
                                />
                            </Form.Item>
                            <Form.Item label="Unloading Location" name="Unloading_Location" rules={[
                                { required: true, message: "Please enter unloading location" },
                            ]}>
                                <Input onChange={handleInputChange} />
                            </Form.Item>
                            <Form.Item name="Remarks" label="Remarks">
                                <Input type="text" />
                            </Form.Item>
                            {/* <Form.Item label="Unloading Agency Rate" name="Unloading_Rate.Rate">
                                <Input type="number" />
                            </Form.Item> */}
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                        className={classes["grid-position"]}
                    >
                        <Grid item lg={12} md={12} xs={12} className={classes["grid-fixed"]}>

                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                        className={classes["grid-position"]}
                    >
                        <Grid item lg={12} md={12} xs={12} className={classes["grid-fixed"]}>
                            <Upload
                                customRequest={customRequest}
                                multiple
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChange}
                                name="Unloading_Images"
                                withCredentials={true}
                                beforeUpload={beforeUpload}
                                accept="image/png, image/jpeg, image/svg+xml, image/bmp, image/x-png, application/pdf"
                            >
                                {fileList.length >= maxNoOfUploadsAllowed ? null : uploadButton}
                            </Upload>
                            <Modal
                                open={previewOpen}
                                title={previewTitle}
                                footer={null}
                                onCancel={handleCancel}
                                width={800}
                                styles={{ body: { height: '500px', overflowY: 'scroll', maxHeight: '500px' } }}
                            >
                                <div
                                    className="rpv-core__viewer"
                                    style={{
                                        border: '1px solid rgba(0, 0, 0, 0.3)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                    }}
                                >
                                    {viewPdf && (
                                        <>
                                            <div
                                                style={{
                                                    alignItems: 'center',
                                                    backgroundColor: '#eeeeee',
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                                    display: 'flex',
                                                    padding: '0.25rem',
                                                }}
                                            >
                                                <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
                                            </div>
                                            <>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.js`}>
                                                        <Viewer fileUrl={viewPdf} plugins={[toolbarPluginInstance]} />
                                                    </Worker>
                                                </div>
                                            </>
                                        </>
                                    )}
                                    {previewImage && ( // Render image if not viewing PDF
                                        <img
                                            alt="example"
                                            style={{
                                                width: "100%",
                                            }}
                                            src={previewImage}
                                        />
                                    )}
                                </div>
                            </Modal>
                        </Grid>
                    </Grid>
                    <div className={classes["submit-button-area"]}>
                        <UIButton variant="contained" color="primary" type="submit" className={classes['submit-button']} disabled={isSubmittingResponse}>
                            Save
                        </UIButton>
                        <UIButton
                            variant="outlined"
                            color="error"
                            onClick={backToloadingsList}
                        >
                            Cancel
                        </UIButton>
                    </div>
                </Form>
            </div>
        </>
    );
};