import React, { useEffect, useState } from "react";
import {
    Card,
    Pagination,
    Spin,
    Input,
    Drawer,
    DatePicker,
    Form,
    notification,
    Modal,
    Upload,
    Select,
    Row,
    Col,
    Tooltip,
    Popconfirm,
    Checkbox
} from "antd";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import {
    EditOutlined,
    EyeOutlined,
    FilterOutlined,
    ImportOutlined,
    SearchOutlined,
    UploadOutlined,
    LoadingOutlined,
    DeleteOutlined,
    QuestionCircleOutlined
} from "@ant-design/icons";
import classes from "./UnloadingData.module.scss";
import { Link } from "react-router-dom";
import "../../../styles/index.scss";
import AppHelper from "../../../helpers/AppHelper";
import constantsArr from "../../../constants/global-constants";
import dayjs from "dayjs";
import importUnloadingSampleFile from '../../../assets/import-samples/unloading_import_sample_file.xlsx';
import totalCountImg from "../../../assets/images/HeaderIcons/totalcount.svg"
import totalWeightImg from "../../../assets/images/HeaderIcons/totalWeight.svg";
import avgWeightImg from "../../../assets/images/HeaderIcons/avgWeight.svg";
import Search from "antd/es/input/Search";

export const UnloadingData = () => {
    const [agencyData, setAgencyData] = useState([]);
    const params = new URLSearchParams(document.location.search);
    const [loading, setLoading] = useState(true);
    const [showPagination, setShowPagination] = useState(false);
    const [currentPagePagination, setCurrentPagePagination] = useState(params.get('currentPage') ?? 1);
    const [currentPageSizePagination, setCurrentPageSizePagination] = useState(constantsArr.DEFAULT_PAGINATION_SIZE);
    const [open, setOpen] = useState(false);
    const { RangePicker } = DatePicker;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [agencies, setAgencies] = useState([]);
    const [unloadingAgencyDropDown, setUnoadingAgencyDropDown] = useState([]);
    const [transportationAgencyDropDown, setTransportationAgencyDropDown] = useState([]);
    const sessionGlobalValues = AppHelper.decryptText(sessionStorage.getItem("globalValues"));
    const [vehicleLists, setVehicleLists] = useState([]);
    const [vehicleDropDown, setVehicleDropDown] = useState([]);
    const [rangePickerStartDateValue, setRangePickerStartDateValue] = useState("")
    const [rangePickerEndDateValue, setRangePickerEndDateValue] = useState("")
    const [currentSetFilterValues, setCurrentSetFilterValues] = useState({})
    const [currentPage, pageSize] = [params.get('currentPage') ?? constantsArr.PAGINATION_CURRENT_PAGE, params.get('pageSize') ?? constantsArr.DEFAULT_PAGINATION_SIZE];
    const [unloadingFilterForm] = Form.useForm()
    const [excelImportErrors, setExcelImportErrors] = useState({})
    const [isModalOpenExcelErrors, setIsModalOpenExcelErrors] = useState(false)
    const [uploadedFile, setUploadedFile] = useState({})
    const maxUploadedImportFileSize = constantsArr.MAX_IMPORT_EXCEL_FILE_SIZE_MB * 1024 * 1024
    const allowedImportFileTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
    const [netWeightStartFilter, setNetWeightStartFilter] = useState("")
    const [netWeightEndFilter, setNetWeightEndFilter] = useState("")
    const [sortValue, setSortValue] = useState('updatedAt:desc'); // Default sorting
    const [searchValue, setSearchValue] = useState('');
    const [sortSpinning, setSortSpinning] = useState(false);
    const sortUpdatedAt = params.get('sort');
    const [hideDeleteIcon, setHideDeleteIcon] = useState(false); // State to manage delete icon visibility
    const [isGeneratedEntriesModalOpen, setGeneratedEntriesIsModalOpen] = useState(false);
    const [modelItem, setModelItem] = useState({});
    const [unloadingLocationDropDown, setUnloadingLocationDropDown] = useState([]);
    const [pendingClientBillChecked, setPendingClientBillChecked] = useState(false)
    const pageSizeFull = constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE
    const dateFormat = "DD-MM-YYYY";
    const props = {
        name: 'file',
        action: false,
        beforeUpload: file => checkBeforeUpload(file),
        maxCount: 1,
        onRemove: () => {
            setUploadedFile({});
        },
        uploadedFile
    };

    const changePageNumber = (currentPage, pageSize) => {
        setCurrentPageSizePagination(pageSize);
        if (currentPage === currentPagePagination && currentPageSizePagination === pageSize) {
            // page number is being changed then go to next page
            currentPage++;
        }
        loadAllUnLoadings(sortValue, currentPage, pageSize, currentSetFilterValues);
    };

    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const resetFilterForm = (e) => {
        e.preventDefault()
        unloadingFilterForm.resetFields()
        setRangePickerStartDateValue("")
        setRangePickerEndDateValue("")
        setNetWeightStartFilter("")
        setNetWeightEndFilter("")
        setPendingClientBillChecked(false)
    }

    const onChangeCheckbox = (e) => {
        setPendingClientBillChecked(e.target.checked)
    }

    const showGeneratedEntriesModel = (e, item) => {
        setGeneratedEntriesIsModalOpen(true);
        setModelItem(item)
    }

    const onCancelGeneratedEntriesModel = () => {
        setGeneratedEntriesIsModalOpen(false);
    }

    const handleModelExcelErrorsCancel = () => setIsModalOpenExcelErrors(false)

    const loadAllUnLoadings = (sortOrder, currentPage, pageSize, filterArgs = []) => {
        setSortSpinning(true);
        setCurrentPagePagination(currentPage);
        AppHelper.getAllUnloadings(sortOrder, currentPage, pageSize, true, filterArgs).then((response) => {
            setSortSpinning(false);
            setLoading(false);
            if (response?.error) {
                console.log(response?.error?.data);
            } else {
                setAgencyData(response);
                setShowPagination(true);
            }
        });
    };

    const handleChange = (e) => {
        if (e.target.name === 'Net_Weight_start') {
            setNetWeightStartFilter(e.target.value)
        } else if (e.target.name === 'Net_Weight_end') {
            setNetWeightEndFilter(e.target.value)
        }
    }

    const handleRangePicker = (e) => {
        if (e) {
            setRangePickerStartDateValue(dayjs(e[0]).format("YYYY-MM-DD") ?? "")
            setRangePickerEndDateValue(dayjs(e[1]).format("YYYY-MM-DD") ?? "")
        } else {
            setRangePickerStartDateValue("")
            setRangePickerEndDateValue("")
        }
    }

    const checkBeforeUpload = (file) => {
        const fileType = file?.type ?? ""
        const fileSize = file?.size ?? 0
        setUploadedFile({ ...uploadedFile, file })
        if (!fileType) {
            notification.error({ message: "Error", description: "Invalid file detected" })
            return false
        }

        if (allowedImportFileTypes.includes(fileType)) {
            // check size of uploaded file should not be too big
            if (fileSize > maxUploadedImportFileSize) {
                notification.error({ message: "Error", description: "The uploaded file exceeds the maximum allowable size of 8 MB." })
                return false
            }
        } else {
            // wrong file type detected throw error
            notification.error({ message: "Error", description: "The uploaded file must be in either the xls or xlsx format." })
            return false
        }
        return false
    }

    const showDrawer = () => setOpen(true);

    const onClose = () => setOpen(false);

    const showModalPopUp = () => {
        setIsModalOpen(true)
        setHideDeleteIcon(false);
    }

    const handleModelCancel = () => setIsModalOpen(false)

    const handleUploadFiles = async () => {
        if (Object.keys(uploadedFile).length === 0) {
            notification.error({ message: "Error", description: "Please upload file to import" })
            return false
        }
        // Set loading to true when upload starts
        setLoading(true);
        setHideDeleteIcon(true); // Hide delete icon when upload button is clicked
        const fileType = uploadedFile?.file?.type ?? ""
        const fileSize = uploadedFile?.file?.size ?? 0
        if (!fileType) {
            notification.error({ message: "Error", description: "Invalid file detected" });
            setLoading(false); // Set loading to false when upload finishes
            return false;
        }

        if (allowedImportFileTypes.includes(fileType)) {
            // check size of uploaded file should not be too big
            if (fileSize > maxUploadedImportFileSize) {
                notification.error({ message: "Error", description: "The uploaded file exceeds the maximum allowable size of 8 MB." })
                setLoading(false);
                return false
            }
        } else {
            // wrong file type detected throw error
            notification.error({ message: "Error", description: "The uploaded file must be in either the xls or xlsx format." })
            setLoading(false);
            return false
        }
        // call to an api to import
        const fileImportResponse = await AppHelper.importUnLoadingExcel(uploadedFile)
        if (fileImportResponse && Object.keys(fileImportResponse).length > 0) {
            setExcelImportErrors(fileImportResponse);
            setIsModalOpenExcelErrors(true);
            setIsModalOpen(false);
            setLoading(false);
        } else {
            // Check if fileImportResponse is undefined or null
            if (fileImportResponse === undefined || fileImportResponse === null || fileImportResponse === "ERR_NETWORK") {
                // Show error message if fileImportResponse is undefined or null
                notification.error({
                    message: "Error",
                    description: "Failed to import data. Please try again later.",
                });
                setLoading(false);
            } else {
                setIsModalOpenExcelErrors(false)
                setIsModalOpen(false)
                loadAllUnLoadings(sortValue, currentPage, pageSize, currentSetFilterValues);
                setLoading(false);
                notification.success({
                    message: "Success",
                    description: "Unloading data imported successfully!",
                    duration: 3,
                });
            }
        }
    };

    const filterUnloadings = async (formValues) => {
        const unloadingAgency = agencies.find((item) => item?.attributes?.Company_name === formValues?.Unloading_Agency && item?.attributes?.Project?.data?.id === sessionGlobalValues?.projectGlobal)
        const transportationAgency = agencies.find((item) => item?.attributes?.Company_name === formValues?.Transporter_Agency && item?.attributes?.Project?.data?.id === sessionGlobalValues?.projectGlobal)
        const vehicleDetailsByNumber = vehicleLists?.data?.find((obj) => obj?.Vehicle_Number === formValues?.Vehicle_Number);
        formValues.Unloading_Agency = unloadingAgency?.id ?? ""
        formValues.Transporter_Agency = transportationAgency?.id ?? ""
        formValues.Vehicle_Number = vehicleDetailsByNumber?.id ?? ""
        formValues.Date_of_Unloading = `${rangePickerStartDateValue}~${rangePickerEndDateValue}`
        formValues.netWeight = `${netWeightStartFilter}~${netWeightEndFilter}`
        if (pendingClientBillChecked) {
            formValues.is_client_billed = pendingClientBillChecked
        }
        if (currentSetFilterValues && currentSetFilterValues?.Challan_Number) {
            formValues.Challan_Number = currentSetFilterValues?.Challan_Number
        }
        loadAllUnLoadings(sortValue, currentPage, pageSize, formValues);
        setOpen(false)
        setCurrentSetFilterValues(formValues)
    }

    const handleDeleteUnloading = async (loadingId) => {
        const response = await AppHelper.deleteUnloadingById(loadingId);
        if (response?.error) {
            console.error('Error response:', response); // Log the response for debugging
            notification.error({
                message: 'Error: Failed to delete unloading',
                description: response?.error?.message || 'Unknown error',
                duration: 3,
            });
        } else {
            setTimeout(() => {
                notification.success({
                    message: 'Success',
                    description: 'Unloading has been deleted successfully.',
                    duration: 3,
                });
            }, 500)
            loadAllUnLoadings(sortValue, currentPagePagination, pageSize, currentSetFilterValues);
        }
    };

    const handleSearch = (value) => {
        setSearchValue(value);
        currentSetFilterValues.Challan_Number = value
        loadAllUnLoadings(sortValue, currentPagePagination, pageSize, currentSetFilterValues);
    };

    const handleSortChange = (value) => {
        setCurrentPagePagination(1);
        setSortValue(value);
        loadAllUnLoadings(value, 1, pageSize, currentSetFilterValues);
    };

    const formatDate = (dateString) => {
        if (!dateString) return '-';
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    // Function to show a notification when attempting to close modal during uploading
    const showCannotCloseNotification = () => {
        notification.warning({
            message: 'Uploading in Progress',
            description: 'Please wait for the file upload to complete before closing the modal.',
            duration: 3,
        });
    };

    useEffect(() => {
        document.title = "Unloading List";
        AppHelper.getAgenciesByProject(sessionGlobalValues?.projectGlobal).then((response) => {
            setAgencies(response)
            setUnoadingAgencyDropDown(AppHelper.getAgencyDropDown(response, 'Unloading', sessionGlobalValues));
            setTransportationAgencyDropDown(AppHelper.getAgencyDropDown(response, 'Transportation', sessionGlobalValues));
        }).catch(err => {
            console.log(err);
        });

        AppHelper.getUnloadingWithoutPagination(sessionGlobalValues?.projectGlobal, currentPage, pageSizeFull).then((response) => {
            setUnloadingLocationDropDown(AppHelper.getUnloadingLocationWithoutPagination(response))
        })
        // Fetch vehicle and agency data
        AppHelper.getVehiclesWithoutPagination().then((response) => {
            setVehicleLists(response);
            setVehicleDropDown(AppHelper.getVehicleNumberDropDownWithoutPagination(response?.data));
        }).catch(err => {
            console.log(err)
        });
        loadAllUnLoadings(sortUpdatedAt ?? sortValue, currentPage, pageSize);
    }, []);

    return (
        <>
            <Grid
                container
                spacing={0}
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes['header-fixed-unloading']}
            >
                <Grid item lg={8} md={6} xs={12}>
                    <Typography
                        variant="h1"
                        fontSize="1.5rem"
                        fontWeight="bold"
                        className="title-un-loading-text"
                    >
                        Unloading
                    </Typography>
                </Grid>
                <Grid item lg={4} md={6} xs={12} container spacing={2} className={classes["gridmargintop10"]}>
                    <Grid item lg={3} md={3} xs={6} className={classes["form-select-div-search"]}>
                        <Search
                            placeholder="Search By Challan No."
                            className={classes["search-new-entry"]}
                            value={searchValue}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} xs={6} className={classes["form-select-div-select"]}>
                        <Form>
                            <Form.Item label="Sort By">
                                <Select className={classes["select-new-entry"]} defaultValue={sortUpdatedAt ?? 'updatedAt:desc'} onChange={handleSortChange}>
                                    <Select.Option value="updatedAt:desc" >Updated Date</Select.Option>
                                    <Select.Option value="Date_of_Unloading:asc">Date Ascending</Select.Option>
                                    <Select.Option value="Date_of_Unloading:desc">Date Descending</Select.Option>
                                    <Select.Option value="Challan_Number:asc">Challan Number Ascending</Select.Option>
                                    <Select.Option value="Challan_Number:desc">Challan Number Descending</Select.Option>
                                    <Select.Option value="Net_Weight:asc">Net Weight Ascending</Select.Option>
                                    <Select.Option value="Net_Weight:desc">Net Weight Descending</Select.Option>
                                </Select>
                            </Form.Item>
                        </Form>
                    </Grid>
                    <Grid item lg={3} md={3} xs={6}>
                        <UIButton
                            type="primary"
                            onClick={showModalPopUp}
                            variant="outlined"
                            startIcon={<ImportOutlined />}
                            className={classes["import-new-entry"]}
                        >
                            Import
                        </UIButton>
                    </Grid>
                    <Grid item lg={3} md={3} xs={6}>
                        <UIButton
                            onClick={showDrawer}
                            variant="outlined"
                            startIcon={<FilterOutlined />}
                            className={classes["filter-new-entry"]}
                        >
                            Filters
                        </UIButton>
                        <Drawer title="Filters" onClose={onClose} open={open}>
                            <Form layout="vertical" form={unloadingFilterForm} onFinish={filterUnloadings}>
                                <Form.Item label="Transporting Agency" name="Transporter_Agency">
                                    <Select
                                        options={transportationAgencyDropDown}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                        placeholder="Select"
                                    />
                                </Form.Item>
                                <Form.Item label="Unloading Date" name="Date_of_Unloading">
                                    <RangePicker style={{ width: "100%" }} format={dateFormat} onChange={handleRangePicker} />
                                </Form.Item>
                                <Form.Item label="Vehicle Number" name="Vehicle_Number">
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                        style={{ width: "100%" }}
                                        options={vehicleDropDown}
                                        placeholder="Select"
                                    />
                                </Form.Item>
                                <Form.Item label="Unloading Agency" name="Unloading_Agency">
                                    <Select
                                        options={unloadingAgencyDropDown}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                        placeholder="Select"
                                    />
                                </Form.Item>
                                <Row gutter={[24, 24]} wrap={true}>
                                    <Col span={24}>
                                        <Form.Item
                                            messageVariables=""
                                            label="Net Wt in Kg"
                                            wrapperCol={{ span: 30 }}
                                            labelCol={{ span: 30 }}
                                            className={classes['ant-form-item']}
                                        >
                                            <Row gutter={[24, 24]} wrap={true}>
                                                <Col span={12}>
                                                    <Input name="Net_Weight_start" placeholder="From" type="number" addonBefore={<SearchOutlined />} value={netWeightStartFilter} onChange={handleChange} />
                                                </Col>
                                                <Col span={12}>
                                                    <Input name="Net_Weight_end" placeholder="To" type="number" addonBefore={<SearchOutlined />} value={netWeightEndFilter} onChange={handleChange} />
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item label="Thermal Plant Book No." name="Thermal_Plant_Book_no">
                                    <Input
                                        placeholder="Enter Thermal Plant Book No."
                                        addonBefore={<SearchOutlined />}
                                    />
                                </Form.Item>
                                <Form.Item label="Unloading Kanta Slip No." name="Kanta_Slip_No">
                                    <Input
                                        placeholder="Enter Unloading Kanta Slip No."
                                        addonBefore={<SearchOutlined />}
                                    />
                                </Form.Item>
                                <Form.Item label="Unloading By Employee" name="Unloading_by_Employee">
                                    <Input
                                        placeholder="Enter Unloading By Employee"
                                        addonBefore={<SearchOutlined />}
                                    />
                                </Form.Item>
                                <Form.Item label="Unloading Location" name="Unloading_Location">
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                        style={{ width: "100%" }}
                                        options={unloadingLocationDropDown}
                                        placeholder="Select"
                                    />
                                </Form.Item>
                                <Row gutter={[24, 24]} wrap={true}>
                                    <Col span={24}>
                                        <Form.Item
                                            messageVariables=""
                                            wrapperCol={{ span: 30 }}
                                            labelCol={{ span: 30 }}
                                            className={classes['ant-form-item-radio']}
                                            name="pendingClientBill"
                                        >
                                            <Checkbox value={true} onChange={onChangeCheckbox}>Pending client bill</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div className={classes["search-button-area"]}>
                                    <UIButton size="medium" variant="outlined" color="error" onClick={resetFilterForm}>
                                        Reset
                                    </UIButton>
                                    <UIButton
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className={classes["filter-submit-button"]}
                                    >
                                        Apply
                                    </UIButton>
                                </div>
                            </Form>
                        </Drawer>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} xs={12} container spacing={1} className={classes["headerMarginTop"]}>
                    <Grid item lg={3} md={2} xs={1}>
                        <Typography variant="body1" className={`${classes["total-items"]} ${classes["total-item-first"]}`} fontSize={14}>
                            <div className={classes["header-grid-flex"]}>
                                <div><img src={totalCountImg} alt="Total count" className={classes["header-grid-image"]} /></div>
                                <div className={classes["divPadding"]}>
                                    <Typography variant="body" color="white" className={classes["header-text-fontSize"]} title={agencyData?.meta?.totalUnloading ?? 0}>
                                        {AppHelper.formatNumber(agencyData?.meta?.totalUnloading ?? 0)}
                                    </Typography><br></br>
                                    <Typography variant="body" color="white">
                                        Total Count
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={2} xs={1}>
                        <Tooltip title={(agencyData?.meta?.totalWeight ?? 0) + " MT"}>
                            <Typography variant="body1" className={`${classes["total-items"]} ${classes["total-item-second"]}`} fontSize={14}>
                                <div className={classes["header-grid-flex"]}>
                                    <div><img src={totalWeightImg} alt="Total count" className={classes["header-grid-image"]} /></div>
                                    <div className={classes["divPadding"]}>
                                        <Typography variant="body" color="white" className={classes["header-text-fontSize"]} title={agencyData?.meta?.totalWeight ?? 0}>
                                            {AppHelper.formatNumber(agencyData?.meta?.totalWeight ?? 0)}
                                        </Typography><br></br>
                                        <Typography variant="body" color="white">
                                            Total Weight (MT)
                                        </Typography>
                                    </div>
                                </div>
                            </Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item lg={3} md={2} xs={1}>
                        <Tooltip title={agencyData?.meta?.averageWeight ?? 0 + " MT"}>
                            <Typography variant="body1" className={`${classes["total-items"]} ${classes["total-item-third"]}`} fontSize={14}>
                                <div className={classes["header-grid-flex"]}>
                                    <div><img src={avgWeightImg} alt="Total count" className={classes["header-grid-image"]} /></div>
                                    <div className={classes["divPadding"]}>
                                        <Typography variant="body" color="white" className={classes["header-text-fontSize"]} title={agencyData?.meta?.averageWeight ?? 0}>
                                            {AppHelper.formatNumber(agencyData?.meta?.averageWeight ?? 0)}
                                        </Typography><br></br>
                                        <Typography variant="body" color="white">
                                            Avg. Weight (MT)
                                        </Typography>
                                    </div>
                                </div>
                            </Typography>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={0} className={classes["marginTop20"]}>
                {!agencyData?.data?.attributes?.results || sortSpinning ? (
                    <Spin size="large" tip="Please Wait ...">
                        <div className={classes["content"]} />
                    </Spin>
                ) : agencyData?.data?.attributes?.results?.length === 0 ? (
                    // Show message when data is not available
                    <Typography variant="body1">
                        No unloading record is available
                    </Typography>
                ) : (agencyData?.data?.attributes?.results?.map((item) => (
                    <Grid key={item.id} item lg={4} md={6} xs={12}>
                        {console.log("item......", item)}
                        <Card bordered={true} className={classes["card-box"]}>
                            <div className={classes["AgencyDetails"]}>
                                <Typography component="div" gutterBottom fontWeight="bold">
                                    Challan No: {item?.Challan_Number}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Vehicle Number :
                                    <span className={classes["AgencyDetailsValue"]}>
                                        {
                                            item?.Vehicle_Number?.Vehicle_Number
                                        }
                                    </span>
                                </Typography>
                                <Typography gutterBottom variant="body2">
                                    Date of Unloading :
                                    <span className={classes["AgencyDetailsValue"]}>
                                        {formatDate(item?.Date_of_Unloading)}
                                    </span>
                                </Typography>
                                <Typography gutterBottom variant="body2">
                                    Unloading Agency :
                                    <span className={classes["AgencyDetailsValue"]}>
                                        {item?.Unloading_Agency?.Company_name.length > 20 ? <span title={item?.Unloading_Agency?.Company_name}>{item?.Unloading_Agency?.Company_name.substring(0, 20)}....</span> : item?.Unloading_Agency?.Company_name ?? "-"}
                                    </span>
                                </Typography>
                                <Typography gutterBottom variant="body2">
                                    Transporting Agency :
                                    <span className={classes["AgencyDetailsValue"]}>
                                        {item?.Transporting_Agency?.Company_name.length > 20 ? <span title={item?.Transporting_Agency?.Company_name}>{item?.Transporting_Agency?.Company_name.substring(0, 20)}....</span> : item?.Transporting_Agency?.Company_name ?? "-"}
                                    </span>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Net Weight :
                                    <span className={classes["AgencyDetailsValue"]}>
                                        {(item?.Net_Weight / 1000).toFixed(2)} MT
                                    </span>
                                </Typography>
                            </div>

                            <div className={classes["card-button"]}>
                                <ul className={classes["action-items"]}>
                                    <li>
                                        <Link to={`/unloadings/view/${item?.id}?currentPage=${currentPagePagination}&pageSize=${pageSize}`}>
                                            <EyeOutlined
                                                className={classes["card-icons"]}
                                                style={{ color: "#000" }}
                                            />
                                        </Link>
                                    </li>
                                    {
                                
                                        <li>
                                            <Link to={`/unloadings/edit/${item?.id}?currentPage=${currentPagePagination}&pageSize=${pageSize}`}>
                                                <EditOutlined
                                                    className={classes["card-icons"]}
                                                    style={{ color: "#000" }}
                                                />
                                            </Link>
                                        </li>
                                    }
                                    {
                                        (item?.Loading?.is_client_billed && item?.Loading?.Client_Bill?.id && item?.Loading?.Client_Bill?.id !== "")
                                            || (item?.Loading?.is_loading_billed && item?.Loading?.Loading_Bill?.id && item?.Loading?.Loading_Bill?.id !== "")
                                            || (item?.Loading?.is_transporting_billed && item?.Loading?.Transporting_Bill?.id && item?.Loading?.Transporting_Bill?.id !== "")
                                            || (item?.Loading?.is_unloading_billed && item?.Loading?.Unloading_Bill?.id && item?.Loading?.Unloading_Bill?.id !== "")
                                            ? (
                                                <li>
                                                    <DeleteOutlined
                                                        title="Delete unloading"
                                                        className={`${classes['card-icons']} ${item?.Cancelled || item?.Unloading || item?.hsd_detail ? classes['disabled-icon'] : ''}`}
                                                        style={{ color: item?.Cancelled || item?.Unloading || item?.hsd_detail ? '#fff' : '#ff0000' }}
                                                        onClick={(e) => showGeneratedEntriesModel(e, item)}
                                                    />
                                                </li>
                                            ) : (
                                                <li>
                                                    <Popconfirm
                                                        title="Delete the Entry"
                                                        description="Are you sure, you want to delete this unloading record?"
                                                        icon={<QuestionCircleOutlined style={{ color: '#ff0000' }} />}
                                                        onConfirm={() => handleDeleteUnloading(item?.id)}
                                                    >
                                                        <DeleteOutlined
                                                            title="Delete unloading"
                                                            className={`${classes['card-icons']} ${item?.Cancelled || item?.Unloading || item?.hsd_detail ? classes['disabled-icon'] : ''}`}
                                                            style={{ color: item?.Cancelled || item?.Unloading || item?.hsd_detail ? '#fff' : '#ff0000' }}
                                                        />
                                                    </Popconfirm>
                                                </li>
                                            )}
                                </ul>
                                <div className={
                                    (
                                        item?.Loading?.is_client_billed && item?.Loading?.Client_Bill?.id && item?.Loading?.Client_Bill?.id !== "")
                                        || (item?.Loading?.is_loading_billed && item?.Loading?.Loading_Bill?.id && item?.Loading?.Loading_Bill?.id !== "")
                                        || (item?.Loading?.is_transporting_billed && item?.Loading?.Transporting_Bill?.id && item?.Loading?.Transporting_Bill?.id !== "")
                                        || (item?.Loading?.is_unloading_billed && item?.Loading?.Unloading_Bill?.id && item?.Loading?.Unloading_Bill?.id !== "") ? `${classes["generated-bill-ribbon"]}` : ""
                                }>
                                    <Typography variant="body2">
                                        {(
                                            item?.Loading?.is_client_billed && item?.Loading?.Client_Bill?.id && item?.Loading?.Client_Bill?.id !== "")
                                            || (item?.Loading?.is_loading_billed && item?.Loading?.Loading_Bill?.id && item?.Loading?.Loading_Bill?.id !== "")
                                            || (item?.Loading?.is_transporting_billed && item?.Loading?.Transporting_Bill?.id && item?.Loading?.Transporting_Bill?.id !== "")
                                            || (item?.Loading?.is_unloading_billed && item?.Loading?.Unloading_Bill?.id && item?.Loading?.Unloading_Bill?.id !== "") ? "Generated" : ""}
                                    </Typography>
                                </div>

                                <>
                                    <Modal open={isGeneratedEntriesModalOpen} onCancel={onCancelGeneratedEntriesModel} centered footer={null} width={700}>
                                        <Grid className={classes["alert-bottom-border"]}>
                                            <Typography variant="h5" color="initial" className={classes["alert-text"]}>Alert !</Typography>
                                        </Grid>
                                        <Grid>
                                            <Typography variant="body1" color="#555555" className={classes["alert-description"]}>Editing this entry is only allowed after it's been removed from the generated bills</Typography>
                                        </Grid>
                                        <Grid
                                            direction="row"
                                            justify="center"
                                            alignItems="center"
                                            alignContent="center"
                                            className={classes["generated-entries-modal-table"]}
                                            container
                                        >

                                            <Grid className={classes["generated-entries-model-heading"]}>
                                                <Grid item lg={4} md={4} sm={4} xs={4}><Typography variant="body1" color="#908f8f" paddingLeft="15px">Bill Type</Typography></Grid>
                                                <Grid item lg={5.5} md={5.5} sm={5.5} xs={5.5}><Typography variant="body1" color="#908f8f">Agency Name</Typography></Grid>
                                                <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5}><Typography variant="body1" color="#908f8f">Bill No.</Typography></Grid>
                                            </Grid>
                                            {
                                                modelItem?.Loading?.is_transporting_billed && modelItem?.Loading?.Transporting_Bill?.id && modelItem?.Loading?.Transporting_Bill?.id !== ""
                                                    ?
                                                    <Grid container spacing={0} className={classes["generated-entries-model-description"]}>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}><Typography variant="body1" color="intial" paddingLeft="15px">Transporting Bill</Typography></Grid>
                                                        <Grid item lg={5.5} md={5.5} sm={5.5} xs={5.5}><Typography variant="body1" color="intial">{modelItem?.Transporting_Agency?.Company_name}</Typography></Grid>
                                                        <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5}><Typography variant="body1" color="intial">{modelItem?.Loading?.Transporting_Bill?.bill_number}</Typography></Grid>
                                                    </Grid>
                                                    :
                                                    ""
                                            }
                                            {
                                                modelItem?.Loading?.is_loading_billed && modelItem?.Loading?.Loading_Bill?.id && modelItem?.Loading?.Loading_Bill?.id !== ""
                                                    ?
                                                    <Grid container spacing={0} className={classes["generated-entries-model-description"]}>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}><Typography variant="body1" color="intial" paddingLeft="15px">Loading Bill</Typography></Grid>
                                                        <Grid item lg={5.5} md={5.5} sm={5.5} xs={5.5}><Typography variant="body1" color="intial">{modelItem?.Loading?.Loading_Agency?.Company_name}</Typography></Grid>
                                                        <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5}><Typography variant="body1" color="intial">{modelItem?.Loading?.Loading_Bill?.bill_number}</Typography></Grid>
                                                    </Grid>
                                                    :
                                                    ""
                                            }
                                            {
                                                modelItem?.Loading?.is_unloading_billed && modelItem?.Loading?.Unloading_Bill?.id && modelItem?.Loading?.Unloading_Bill?.id !== ""
                                                    ?
                                                    <Grid container spacing={0} className={classes["generated-entries-model-description"]}>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}><Typography variant="body1" color="intial" paddingLeft="15px">Unloading Bill</Typography></Grid>
                                                        <Grid item lg={5.5} md={5.5} sm={5.5} xs={5.5}><Typography variant="body1" color="intial">{modelItem?.Unloading_Agency?.Company_name}</Typography></Grid>
                                                        <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5}><Typography variant="body1" color="intial">{modelItem?.Loading?.Unloading_Bill?.bill_number}</Typography></Grid>
                                                    </Grid>
                                                    :
                                                    ""
                                            }
                                            {
                                                modelItem?.Loading?.is_client_billed && modelItem?.Loading?.Client_Bill?.id && modelItem?.Loading?.Client_Bill?.id !== ""
                                                    ?
                                                    <Grid container spacing={0} className={classes["generated-entries-model-description"]}>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}><Typography variant="body1" color="intial" paddingLeft="15px">Client Bill</Typography></Grid>
                                                        <Grid item lg={5.5} md={5.5} sm={5.5} xs={5.5}><Typography variant="body1" color="intial">-</Typography></Grid>
                                                        <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5}><Typography variant="body1" color="intial">{modelItem?.Loading?.Client_Bill?.bill_no}</Typography></Grid>
                                                    </Grid>
                                                    :
                                                    ""
                                            }
                                        </Grid>
                                    </Modal>
                                </>
                            </div>
                        </Card>
                    </Grid>
                ))
                )}
            </Grid>
            {showPagination === true && agencyData?.data?.attributes?.results?.length > 0 ? (
                <div className={classes["pagination-box"]}>
                    <Pagination
                        current={currentPagePagination}
                        defaultCurrent={currentPage}
                        defaultPageSize={currentPageSizePagination}
                        showTotal={(total) => `Total ${total} items`}
                        responsive
                        pageSize={currentPageSizePagination}
                        onChange={changePageNumber}
                        showSizeChanger={false}
                        total={agencyData?.data?.attributes?.pagination?.total}
                    />
                </div>
            ) : (
                ""
            )}
            <Modal
                centered
                open={isModalOpen}
                onCancel={loading ? showCannotCloseNotification : handleModelCancel}
                footer={(_) => (
                    <>
                        <UIButton
                            onClick={loading ? showCannotCloseNotification : handleModelCancel}
                            variant="outlined"
                            color="error"
                            className={classes['model-buttons']}
                        >
                            Cancel
                        </UIButton>
                        <UIButton
                            onClick={handleUploadFiles}
                            variant="contained"
                            className={classes["add-new-filter-entry"]}
                            disabled={loading} // Disable the Upload button while loading
                        >
                            {loading ? <Spin indicator={<LoadingOutlined style={{ color: "#fff" }} />} /> : 'Upload'} {/* Show spinner while loading */}
                        </UIButton>
                    </>
                )}
            >

                <Upload {...props}>
                    <UIButton
                        startIcon={<UploadOutlined />}
                        className={classes['upload-file-entry']}
                        variant="outlined"
                    >
                        Browse File
                    </UIButton>
                </Upload>
                <h4>Please choose file .xls and .xlsx only. <a href={importUnloadingSampleFile} download={true}>Click here</a> to download the sample file.</h4>
                <style>{`
         & .ant-upload-list-item-actions .ant-btn {
          display: ${hideDeleteIcon ? 'none' : 'inline-block'};
        }
      `}</style>
            </Modal>
            {/**
       * Modal popup for excel import errors display
       */}
            {
                excelImportErrors && Object.keys(excelImportErrors).length > 0
                    ?
                    <Modal
                        centered
                        open={isModalOpenExcelErrors}
                        onCancel={handleModelExcelErrorsCancel}
                        width={'60%'}
                        className={classes['error-import-modal']}
                        footer=""
                    >
                        <>
                            <h3 className={classes["error-import-modal-heading"]}>Please find errors in unloading import excel data as below:</h3>
                            <div className={classes['error-import-excel-display-data']}>
                                <table border={0} cellPadding={0} cellSpacing={0} width={'100%'}>
                                    <thead>
                                        <tr>
                                            <th width={'8%'} align="center">Row No</th>
                                            <th width={'92%'}>Errors</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Object.entries(excelImportErrors).map(([key, value]) => {
                                                return <tr key={key}>
                                                    <td align="center"><strong>{key}</strong></td>
                                                    <td>
                                                        <table border={0} cellPadding={0} cellSpacing={0} key={key} width={'100%'}>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <ul>
                                                                            {
                                                                                value?.map((item, key) => {
                                                                                    return <li key={key}>
                                                                                        <p>{item}</p>
                                                                                    </li>
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </>
                    </Modal >
                    :
                    ""
            }
        </>
    );
};