import React, { useEffect, useState } from "react";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import {
    DatePicker,
    Drawer,
    Form,
    Input,
    Modal,
    notification,
    Pagination,
    Popconfirm,
    Select,
    Spin,
    Tooltip,
} from "antd";
import classes from "./PaymentDetail.module.scss";
import {
    DeleteOutlined,
    EditOutlined,
    FilterOutlined,
} from "@ant-design/icons";
import dollarCount from "../../../../assets/images/reportsummaryIcons/dollartotal.svg";
import { Link } from "react-router-dom";
import AppHelper from "../../../../helpers/AppHelper";
import constantsArr from "../../../../constants/global-constants";
import dayjs from "dayjs";
import exceljs from 'exceljs'
import FileSaver from 'file-saver'

export const PaymentDetail = () => {
    const params = new URLSearchParams(document.location.search);
    const [filterForm] = Form.useForm()
    const [vehicleForm] = Form.useForm()
    const [open, setOpen] = useState(false);
    const { RangePicker } = DatePicker;
    const dateFormat = constantsArr.GLOBAL_DATE_FORMAT
    const [currentPagePagination, setCurrentPagePagination] = useState(params.get('currentPage') ?? 1)
    const [currentPageSizePagination, setCurrentPageSizePagination] = useState(constantsArr.DEFAULT_PAGINATION_SIZE);
    const [currentPage, pageSize] = [constantsArr.PAGINATION_CURRENT_PAGE, 3];
    const sessionActiveSortOrder = AppHelper.decryptText(sessionStorage.getItem('ledgerPaymentDetailsActiveSortOrder'))
    const sessionActiveFilterValues = AppHelper.decryptText(sessionStorage.getItem('ledgerPaymentDetailsActiveFilterValues'))
    const [ledgerPaymentDetails, setLedgerPaymentDetails] = useState([])
    const [showPagination, setShowPagination] = useState(false)
    const [rangePickerStartDateValue, setRangePickerStartDateValue] = useState("")
    const [rangePickerEndDateValue, setRangePickerEndDateValue] = useState("")
    const [startDateOfUnloadingFilterForm, setStartDateOfUnloadingFilterForm] = useState("")
    const [endDateOfUnloadingFilterForm, setEndDateOfUnloadingFilterForm] = useState("")
    const [agencies, setAgencies] = useState([])
    const sessionGlobalValues = AppHelper.decryptText(sessionStorage.getItem("globalValues"));
    const [agencyDropDown, setAgencyDropDown] = useState([]);
    const [loadingBarResults, setLoadingBarResults] = useState(false)
    const [isOpenEditModel, setIsOpenEditModel] = useState(false)
    const [isExportButtonDisabled, setIsExportButtonDisabled] = useState(false)
    const exportPageSize = constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE
    const dateFormatDayjsConversion = constantsArr.GLOBAL_DATE_FORMAT_DAYJS;

    const allowMaxValuesInputBox = (e) => {
        const weightVal = e.target.value
        if (weightVal.toString().length > 10) {
            e.preventDefault();
        }
    }

    const openEditModel = (recordId) => {
        setIsOpenEditModel(true);
        const findPaymentRecordById = ledgerPaymentDetails?.data?.attributes?.results?.find(item => item?.id === recordId)
        vehicleForm.setFieldsValue({
            recordId: recordId,
            agency: findPaymentRecordById?.agency?.Company_name ?? '',
            date: dayjs(findPaymentRecordById?.date).format(dateFormat) ?? '',
            amount_paid: findPaymentRecordById?.amount_paid ?? '',
        })

    }

    const changePageNumber = (currentPage, pageSize) => {
        // check page no is displaying in url then set that page no otherwise default 1
        setCurrentPageSizePagination(pageSize);
        if (currentPage === currentPagePagination && currentPageSizePagination === pageSize) {
            // page number is being changed then go to next page
            currentPage++;
        }
        setCurrentPagePagination(currentPage)
        loadLedgerPaymentDetails(((sessionActiveSortOrder && sessionActiveSortOrder !== '') ? sessionActiveSortOrder : 'date:asc'), true, currentPage, 3, sessionActiveFilterValues);
    };

    const handleModelCancel = () => {
        setIsOpenEditModel(false)
    }

    const showDrawer = () => setOpen(true)

    const onClose = () => setOpen(false)

    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const filterResults = (formValues) => {
        const agency = agencies.find((item) => item?.attributes?.Company_name === formValues?.Agency && item?.attributes?.Project?.data?.id === sessionGlobalValues?.projectGlobal)
        if (rangePickerStartDateValue && rangePickerEndDateValue !== '') {
            formValues.Date_of_Payment = `${rangePickerStartDateValue}~${rangePickerEndDateValue}`
        } else {
            formValues.Date_of_Payment = `${startDateOfUnloadingFilterForm}~${endDateOfUnloadingFilterForm}`
        }
        formValues.Agency = agency?.id ?? null
        sessionStorage.setItem('ledgerPaymentDetailsActiveFilterValues', AppHelper.encryptText(formValues))
        loadLedgerPaymentDetails(((sessionActiveSortOrder && sessionActiveSortOrder !== '') ? sessionActiveSortOrder : 'date:asc'), true, currentPage, pageSize, formValues);
        setOpen(false)
    }

    const exportLedger = async () => {

        setIsExportButtonDisabled(true)

        const exportResponse = await AppHelper.ledgerPaymentDetails(((sessionActiveSortOrder && sessionActiveSortOrder !== '') ? sessionActiveSortOrder : 'date:asc'), true, 1, exportPageSize, sessionActiveFilterValues)
        if (exportResponse?.error) {
            setIsExportButtonDisabled(false)
            notification.error({
                message: "Error",
                description: exportResponse?.error?.message,
                duration: 3
            })
        } else {
            // export xlsx code goes here
            const workBook = new exceljs.Workbook()
            const workSheetPendingBills = workBook.addWorksheet("Payment Details Ledger Records", {
                views: [{ ySplit: 1, state: 'frozen' }]
            })
            workSheetPendingBills.columns = [
                { header: 'S.NO.', key: "sl_no", width: 10 },
                { header: 'Agency', key: "Agency", width: 25 },
                { header: 'Date', key: "date", width: 25 },
                { header: 'Amount', key: "amount_paid", width: 25 }
            ]

            for (let index = 0; index < exportResponse?.data?.attributes?.results.length; index++) {
                exportResponse.data.attributes.results[index].sl_no = index + 1
                exportResponse.data.attributes.results[index].Agency = (exportResponse?.data?.attributes?.results[index]?.agency?.Company_name && exportResponse?.data?.attributes?.results[index]?.agency?.Company_name !== "") ? exportResponse?.data?.attributes?.results[index]?.agency?.Company_name : '-'
                exportResponse.data.attributes.results[index].date = dayjs(exportResponse?.data?.attributes?.results[index]?.date).format(dateFormat)
                workSheetPendingBills.addRow(exportResponse?.data?.attributes?.results[index])
            }

            // set header to export as excel file
            try {
                const buffer = await workBook.xlsx.writeBuffer()
                const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                const excelExtension = constantsArr.EXPORT_EXCEL_EXTENSION
                const blob = new Blob([buffer], { type: fileType })
                FileSaver.saveAs(blob, `payment_detail_ledger_export_data_${dayjs().format(dateFormat)}.${excelExtension}`)
                // Conclude function now
                notification.success({
                    message: "Payment details (ledger) data exported successfully",
                    description: '',
                    duration: 3
                })
            } catch (error) {
                console.log(error, 'error');
                notification.error({
                    message: "Error",
                    description: exportResponse?.error?.message,
                    duration: 3
                })
            }
            setIsExportButtonDisabled(false)
        }
    }

    const handleRangePicker = (e) => {
        if (e) {
            setRangePickerStartDateValue(dayjs(e[0]).format("YYYY-MM-DD") ?? "")
            setRangePickerEndDateValue(dayjs(e[1]).format("YYYY-MM-DD") ?? "")
        } else {
            setRangePickerStartDateValue("")
            setRangePickerEndDateValue("")
            setStartDateOfUnloadingFilterForm("")
            setEndDateOfUnloadingFilterForm("")
        }
    }

    const updateLedgerEntry = async (formValues) => {
        const agency = agencies.find((item) => item?.attributes?.Company_name === formValues?.agency && item?.attributes?.Project?.data?.id === sessionGlobalValues?.projectGlobal)
        formValues.agency = agency?.id ?? null
        formValues.id = formValues?.recordId ?? null
        formValues.date = dayjs(formValues.date, 'DD-MM-YYYY').format("YYYY-MM-DD")
        const updatePaymentResponse = await AppHelper.updatePayment(formValues)
        if (updatePaymentResponse?.error) {
            notification.error({
                message: 'Error',
                description: updatePaymentResponse?.error?.message,
                duration: 3
            })
        } else {
            setIsOpenEditModel(false)
            loadLedgerPaymentDetails(((sessionActiveSortOrder && sessionActiveSortOrder !== '') ? sessionActiveSortOrder : 'date:asc'), true, currentPagePagination, pageSize, sessionActiveFilterValues);
            notification.success({
                message: 'Success',
                description: 'Payment details record has been updated successfully',
                duration: 3
            })
        }
    }

    const handleSortChange = (value) => {
        // Update the sorting state
        setCurrentPagePagination(1);
        // store selected sort value in session
        sessionStorage.setItem('ledgerPaymentDetailsActiveSortOrder', AppHelper.encryptText(value))
        loadLedgerPaymentDetails(value, true, 1, pageSize, sessionActiveFilterValues);
    };

    const resetFilterForm = (e) => {
        e.preventDefault()
        filterForm.resetFields()
        setRangePickerStartDateValue("")
        setRangePickerEndDateValue("")
        setStartDateOfUnloadingFilterForm("")
        setEndDateOfUnloadingFilterForm("")
        sessionStorage.removeItem('ledgerPaymentDetailsActiveFilterValues')
    }

    const loadLedgerPaymentDetails = (sortOrder, pagination, currentPage, pageSize, formValues = {}, agencyId = null) => {
        setLoadingBarResults(true)
        AppHelper.ledgerPaymentDetails(sortOrder, pagination, currentPage, pageSize, formValues, agencyId).then((res) => {
            setLoadingBarResults(false)
            setLedgerPaymentDetails(res)
            setShowPagination(true)
        }).catch(err => {
            setLoadingBarResults(false)
            setShowPagination(false)
            notification.error({
                message: 'Error',
                description: err?.error.message,
                duration: 3
            })
        })
    }

    const deletePaymentDetails = (recordId) => {
        AppHelper.deletePayment(recordId).then((res) => {
            loadLedgerPaymentDetails(((sessionActiveSortOrder && sessionActiveSortOrder !== '') ? sessionActiveSortOrder : 'date:asc'), true, currentPage, pageSize, sessionActiveFilterValues);
            notification.success({
                message: 'Success',
                description: 'Payment details record has been deleted successfully',
                duration: 3
            })
        }).catch(err => {
            setLoadingBarResults(false)
            setShowPagination(false)
            notification.error({
                message: 'Error',
                description: err?.error.message,
                duration: 3
            })
        })
    }

    useEffect(() => {
        AppHelper.getAgenciesByProject(sessionGlobalValues?.projectGlobal).then((response) => {
            setAgencies(response)
            setAgencyDropDown(AppHelper.getAgencyDropDown(response, '', sessionGlobalValues));
            if (sessionActiveFilterValues && Object.keys(sessionActiveFilterValues).length > 0) {
                const agencyName = response.find((item) => item?.id === sessionActiveFilterValues?.Agency && item?.attributes?.Project?.data?.id === sessionGlobalValues?.projectGlobal)
                if (sessionActiveFilterValues?.Date_of_Payment !== '') {
                    const breakDateOfPaymentFilterStorage = sessionActiveFilterValues?.Date_of_Payment.split("~")
                    if (breakDateOfPaymentFilterStorage[0] && breakDateOfPaymentFilterStorage[0] !== "") {
                        const startDateRangePaymentDateDefault = breakDateOfPaymentFilterStorage[0]
                        const endDateRangeUnPaymentDateDefault = breakDateOfPaymentFilterStorage[1]
                        setStartDateOfUnloadingFilterForm(startDateRangePaymentDateDefault ? dayjs(dayjs(startDateRangePaymentDateDefault, dateFormatDayjsConversion)) : null)
                        setEndDateOfUnloadingFilterForm(endDateRangeUnPaymentDateDefault ? dayjs(dayjs(endDateRangeUnPaymentDateDefault, dateFormatDayjsConversion)) : null)
                    }
                }
                filterForm.setFieldsValue({
                    Agency: agencyName?.attributes?.Company_name ?? null
                })
            }
        });

        loadLedgerPaymentDetails(((sessionActiveSortOrder && sessionActiveSortOrder !== '') ? sessionActiveSortOrder : 'date:asc'), true, currentPage, pageSize, sessionActiveFilterValues);
    }, [])


    return (
        <>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
                className={classes["payment-details"]}
            >
                <Grid lg={12} md={12} sm={12} xs={12} container spacing={2}>
                    <Grid item lg={9} md={12} sm={12} xs={12}>
                        <div className={classes["flex-first-item"]}>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={12} sm={12} xs={12}>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justify="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="wrap"
                        >
                            <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                container
                                spacing={2}
                                className={classes["margintop20"]}
                            >
                                <Grid item lg={9} md={9} sm={9} xs={9}>
                                    <Form>
                                        <Form.Item label="Sort By">
                                            <Select defaultValue={((sessionActiveSortOrder && sessionActiveSortOrder !== '') ? sessionActiveSortOrder : 'date:asc')} className={classes["select-new-entry"]} id="sortOrder" onChange={handleSortChange}>
                                                <Select.Option value="date:asc">Date Ascending</Select.Option>
                                                <Select.Option value="date:desc">Date Descending</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Form>
                                </Grid>
                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                    <UIButton
                                        variant="outlined"
                                        startIcon={<FilterOutlined />}
                                        className={classes["new-entry-filter"]}
                                        onClick={showDrawer}
                                    >
                                        Filter
                                    </UIButton>

                                    <Drawer title="Filters" onClose={onClose} open={open}>
                                        <Form layout="vertical" form={filterForm} onFinish={filterResults}>
                                            <Form.Item label="Date" name="Date">
                                                <RangePicker style={{ width: "100%" }} picker="date" onChange={handleRangePicker} format={dateFormat} defaultValue={[startDateOfUnloadingFilterForm, endDateOfUnloadingFilterForm]} />
                                            </Form.Item>
                                            <Form.Item label="Agency" name="Agency">
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    placeholder="All"
                                                    optionFilterProp="children"
                                                    filterOption={filterOption}
                                                    options={agencyDropDown}
                                                    className={classes["select-new-entry"]}
                                                />
                                            </Form.Item>
                                            <div className={classes["search-button-area"]}>
                                                <UIButton size="medium" variant="outlined" color="error" onClick={resetFilterForm}>
                                                    Reset
                                                </UIButton>
                                                <UIButton
                                                    size="medium"
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    className={classes["add-new-filter-entry"]}
                                                >
                                                    Apply
                                                </UIButton>
                                            </div>
                                        </Form>
                                    </Drawer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["wrapper-padding"]}
            >
                <Grid lg={12} md={12} sm={12} xs={12} container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-second"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={dollarCount}
                                        alt="active vehicle"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Typography
                                        variant="body"
                                        color="white"
                                        className={classes["header-text-fontSize"]}
                                    >
                                        &#x20b9; {ledgerPaymentDetails?.data?.attributes?.totalAmountPaid.toLocaleString('en-in') ?? 0}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body" color="white">
                                        Total Amount Paid
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                </Grid>
                {
                    loadingBarResults
                        ?
                        <div className={classes['summary-table-spin-element']}><Spin /></div>
                        :
                        ledgerPaymentDetails?.data?.attributes?.results?.length > 0
                            ?
                            <>
                                <Grid
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    container
                                    spacing={2}
                                    marginTop={5}
                                >
                                    <Grid item lg={10} md={8} sm={8} xs={12} textAlign="center">
                                        <Grid
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            container
                                            spacing={2}
                                            className={classes["entry-details"]}
                                            marginLeft={0}
                                        >
                                            <Grid item lg={3} md={3} sm={3} xs={3}>
                                                <Typography variant="body1" color="initial">
                                                    <strong>SL No</strong>
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={3} md={3} sm={3} xs={3}>
                                                <Typography variant="body1" color="initial">
                                                    <strong>Agency</strong>
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={3} md={3} sm={3} xs={3}>
                                                {" "}
                                                <Typography variant="body1" color="initial">
                                                    <strong>Date</strong>
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={3} md={3} sm={3} xs={3}>
                                                <Typography variant="body1" color="initial">
                                                    <strong>Amount</strong>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {
                                    ledgerPaymentDetails?.data?.attributes?.results?.map((value, key) => {
                                        return (
                                            <Grid
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                                container
                                                spacing={2}
                                                marginTop={5}
                                                key={key}
                                            >
                                                <Grid item lg={10} md={8} sm={8} xs={12} textAlign="center">
                                                    <Grid
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        xs={12}
                                                        container
                                                        spacing={2}
                                                        className={classes["entry-details"]}
                                                        marginLeft={0}
                                                    >
                                                        <Grid item lg={3} md={3} sm={3} xs={3}>
                                                            <Typography variant="body1" color="initial">
                                                                {
                                                                    (currentPagePagination === 1 ? key + 1 : ((key + 1) + pageSize))
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item lg={3} md={3} sm={3} xs={3}>
                                                            {" "}
                                                            <Typography variant="body1" color="initial">
                                                                {value?.agency?.Company_name ?? ''}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item lg={3} md={3} sm={3} xs={3}>
                                                            {" "}
                                                            <Typography variant="body1" color="initial">
                                                                {dayjs(value?.date).format("DD-MM-YYYY")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item lg={3} md={3} sm={3} xs={3}>
                                                            <Typography variant="body1" color="initial">
                                                                &#x20b9; {value?.amount_paid.toLocaleString('en-in')}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={1}
                                                    md={2}
                                                    sm={2}
                                                    xs={6}
                                                    sx={{
                                                        marginTop: { xs: "10px", sm: "0px" },
                                                        textAlign: { xs: "left", sm: "right" },
                                                    }}
                                                >
                                                    <Tooltip title="Edit">
                                                        <Link className={classes["bill-number-icons"]} onClick={() => openEditModel(value?.id)} >
                                                            <EditOutlined style={{ color: "#555555" }} />
                                                        </Link>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={1}
                                                    md={2}
                                                    sm={2}
                                                    xs={6}
                                                    sx={{
                                                        marginTop: { xs: "10px", sm: "0px" },
                                                        textAlign: { xs: "left", sm: "right" },
                                                    }}
                                                >
                                                    <Popconfirm
                                                        title="Delete the task"
                                                        description="Are you sure, you want to delete this payment record?"
                                                        okText="Yes"
                                                        cancelText="No"
                                                        onConfirm={() => deletePaymentDetails(value?.id)}
                                                    >
                                                        <Link className={classes["bill-number-icons"]}>
                                                            <DeleteOutlined style={{ color: "#ff0000" }} title="Delete Payment" />
                                                        </Link>
                                                    </Popconfirm>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </>
                            :
                            <Grid
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                container
                                spacing={2}
                                marginTop={5}
                            >
                                <Grid item lg={12} md={12} sm={12} xs={12} textAlign="center">
                                    <Grid
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        container
                                        spacing={2}
                                        className={classes["entry-details"]}
                                        marginLeft={0}
                                    >
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography variant="body1" color="initial">
                                                No payment detail record is here.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                }
            </Grid >
            <>
                {
                    showPagination === true && ledgerPaymentDetails?.data?.attributes?.results?.length > 0 ? (
                        <div className={classes["pagination-position"]}>
                            <Pagination
                                current={currentPagePagination}
                                showSizeChanger={false}
                                defaultCurrent={currentPage}
                                defaultPageSize={currentPageSizePagination}
                                showTotal={(total) => `Total ${total} items`}
                                responsive
                                pageSize={ledgerPaymentDetails?.data?.attributes?.pagination?.pageSize}
                                onChange={changePageNumber}
                                total={ledgerPaymentDetails?.data?.attributes?.pagination?.total}
                            />
                        </div>
                    ) : (
                        ""
                    )
                }
            </>
            {
                ledgerPaymentDetails?.data?.attributes?.results?.length > 0
                    ?
                    <Grid className={classes["export-submit-button"]}>
                        <UIButton
                            variant="outlined"
                            className={`${classes["filter-new-entry"]} ${classes["export-button"]} ${isExportButtonDisabled ? classes['button-disabled'] : ''}`} onClick={exportLedger}
                            disabled={isExportButtonDisabled}
                        >
                            Export
                        </UIButton>
                    </Grid>
                    :
                    ""
            }
            {
                isOpenEditModel
                    ?
                    <Modal
                        centered
                        open={isOpenEditModel}
                        onCancel={handleModelCancel}
                        width={'50%'}
                        className={classes['error-import-modal']}
                        footer=""
                    >
                        <>
                            <h3 className={classes["error-import-modal-heading"]}>Update Payment Amount Entry</h3>
                            <div className={classes['error-import-excel-display-data']}>
                                <Form layout="vertical" form={vehicleForm} onFinish={updateLedgerEntry}>
                                    <Form.Item name="recordId" label={false} hidden={true}>
                                        <Input type="hidden" name="id" />
                                    </Form.Item>
                                    <Grid
                                        container
                                        spacing={1}
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        alignContent="center"
                                        wrap="wrap"
                                        className={classes["grid-space"]}
                                    >
                                        <Grid item lg={6} md={6} xs={12} className={classes["position-relative"]}>
                                            <Form.Item
                                                label="Agency"
                                                name="agency"
                                            >
                                                <Input className={classes['width88']} readOnly disabled />
                                            </Form.Item>
                                        </Grid>
                                        <Grid item lg={6} md={6} xs={12}>
                                            <Form.Item
                                                label="Date"
                                                name="date"
                                            >
                                                <Input className={classes['width88']} readOnly disabled />
                                            </Form.Item>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={1}
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        alignContent="center"
                                        wrap="wrap"
                                        className={classes["grid-space"]}
                                    >
                                        <Grid item lg={6} md={6} xs={12}>
                                            <Form.Item name="amount_paid" label="Amount" rules={[
                                                {
                                                    required: true, message: "Amount can not be blank"
                                                }
                                            ]}>
                                                <Input type="number" min={0} max={999999999999} step={0.1} onKeyPress={allowMaxValuesInputBox} />
                                            </Form.Item>
                                        </Grid>
                                    </Grid>
                                    <div className={classes["submit-button-area"]}>
                                        <UIButton variant="contained" color="primary" type="submit" className={classes['submit-button']}>
                                            Update
                                        </UIButton>
                                        <UIButton
                                            variant="outlined"
                                            color="error"
                                            onClick={handleModelCancel}
                                        >
                                            Cancel
                                        </UIButton>
                                    </div>
                                </Form >
                            </div>
                        </>
                    </Modal >
                    :
                    ""
            }
        </>
    );
};
